import React from "react";

function Cerchio1M() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1083.44 1227.96">
      <path
        d="M540.5 0C321.34 0 126.1 83.87 0 214.56v798.84c126.1 130.69 321.34 214.56 540.5 214.56s416.92-84.97 542.94-217.12V217.12C957.42 84.97 761.08 0 540.5 0zm0 785.7c-235.7 0-426.77-146.26-426.77-326.69S304.8 132.32 540.5 132.32s426.77 146.26 426.77 326.69S776.2 785.7 540.5 785.7z"
        className="fill-[#f5f3f6]"
        data-name="Livello_1"
      ></path>
    </svg>
  );
}

export default Cerchio1M;