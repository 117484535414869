import { auth, db, messaging } from "./firebase";
import {
    useEffect,
    useState,
    lazy,
    Suspense,
    createContext,
    useReducer,
    useContext,
} from "react";
import Cookies from "universal-cookie";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Caricamento from "./Components/Atoms/Caricamento/Caricamento";
import Sidebar from "./Components/Molecules/Sidebar/Sidebar";
import Navbar from "./Components/Molecules/Sidebar/Navbar";
import Footer from "./Components/Molecules/Footer/Footer";
import {
    collection,
    doc,
    getDoc,
    getDocs,
    updateDoc,
} from "firebase/firestore";
import Preferiti from "./Pages/Preferiti/Preferiti";
import ProdottoAperto from "./Pages/Prodotto/ProdottoAperto";
import Search from "./Pages/Search/Search";
import Profili from "./Pages/Profili/Profili";
import globals from "./utils/Globals";
import DrawersPrimoLogin from "./Components/Atoms/Modals/DrawersPrimoLogin";
import React from "react";
import Registrato from "./Pages/Registrato/Registrato";
import VerificaMail from "./Components/Atoms/Modals/VerificaMail";
import Funziona from "./Pages/Funziona/Funziona";
import Chisiamo from "./Pages/Chisiamo/Chisiamo";
import Problemi from "./Pages/Problemi/Problemi";
import { getToken } from "firebase/messaging";
import { rootReducer, ReducerContext, initialState } from "./rootReducer";
import useDeviceDetection from "./utils/useDeviceDetection";
import useUserStore from "./stores/userStore";
import useFirstLoginStore from "./stores/firstLoginStore";
import RecuperoPassword from "./Pages/RecuperoPassword/RecuperoPassword";
import { Toaster } from "sonner";

import { getHomeTutorial } from "./Components/Molecules/Tutorial/tutorials";
import Facciata from "./Pages/Landing/Facciata";
import LocalHeroes from "./Pages/Landing/LocalHeroes/LocalHeroes";

// Importazioni lazy

const Registration = lazy(() => import("./Pages/Register/SteppedRegistration"));
const Home = lazy(() => import("./Pages/Home/Home"));
const ProdottoApertoP = lazy(() => import("./Pages/Prodotto/ProdottoApertoP"));
const Chat = lazy(() => import("./Pages/Chat/Chat"));
const Notifications = lazy(() => import("./Pages/Notifications/Notifications"));
const Profilo = lazy(() => import("./Pages/Profilo/Profilo"));
const Login = lazy(() => import("./Pages/Login/Login"));
const Community = lazy(() => import("./Pages/Community/Community"));
const Exit = lazy(() => import("./Components/Atoms/Exit"));
const SidebarPhone = lazy(() =>
    import("./Components/Molecules/Sidebar/SidebarPhone")
);

export const Provider = ({ children, state, dispatch }) => {
    return (
        <ReducerContext.Provider value={{ state, dispatch }}>
            {children}
        </ReducerContext.Provider>
    );
};

function App() {
    const [logged, setLogged] = [
        useUserStore((state) => state.isLogged),
        useUserStore((state) => state.setIsLogged),
    ];
    const [loaded, setLoaded] = useState(false);
    const user = useUserStore((state) => state.user);
    const setUser = useUserStore((state) => state.setUser);
    const setFirstLogin = useFirstLoginStore((state) => state.setFirstLogin);
    const setNome = useFirstLoginStore((state) => state.setNome);
    const cookies = new Cookies();

    const [state, dispatch] = useReducer(rootReducer, initialState);
    const device = useDeviceDetection();
    const isMobile = device === "Mobile";

    useEffect(() => {
        // Il listener viene chiamato ogni volta che lo stato di autenticazione cambia

        const unsubscribe = auth.onAuthStateChanged(async (utente) => {
            try {
                if (utente) {
                    const docRef = doc(db, "users", utente.uid);
                    const docData = await getDoc(docRef);

                    if (docData.exists()) {
                        console.log(docData.data());
                        const livello =
                            docData.data().livello && docData.data().livello > 0
                                ? docData.data().livello
                                : 0;
                       const coordinate = docData.data().comune && docData.data().comune.length > 0
                                ? {
                                      lat: docData.data().lat,
                                      lng: docData.data().lng,
                                      comune: docData.data().comune,
                                  }
                                : {
                                      lat: 45.4628245,
                                      lng: 9.0951606,
                                      comune: "Milano",
                                  };

                        setUser({
                            ...docData.data(),
                            id: utente.uid,
                            uid: utente.uid,
                            livello: livello,
                            lat: coordinate.lat,
                            lng: coordinate.lng,
                            comune: coordinate.comune,
                        });

                        setLogged(true);
                        setFirstLogin(docData.data().firstLogin);
                        setNome(docData.data().nome);
                    }
                } else {
                    // Utente non autenticato
                    setUser(null);
                    setLogged(false);
                }
            } catch (error) {
                console.error("Error fetching user data:", error);
            } finally {
                console.log(user);

                setLoaded(true);
            }
        });

        // Pulizia del componente per evitare perdite di memoria
        return () => unsubscribe();
    }, []);

    // Disabilita la selezione di testo su tutti gli elementi eccetto h1, h2, h3, h4, h5, h6, p, a, span, li, label
    document.addEventListener("DOMContentLoaded", function () {
        const elements = document.querySelectorAll(
            "*:not(h1):not(h2):not(h3):not(h4):not(h5):not(h6):not(p):not(a):not(span):not(li):not(label)"
        );
        elements.forEach((element) => {
            element.classList.add("no-select");
        });
    });

    ////////////////////////////////////// CONSOLE CLEAR ******************************************************
    useEffect(() => {
        //console.clear();
    });
    const fullScreen = logged
        ? ["login", "register", "chisiamo", "localheroes"].includes(
              window.location.pathname.split("/")[1]
          )
            ? true
            : false
        : ["login", "register", "", "localheroes", "recupero"].includes(
              window.location.pathname.split("/")[1]
          )
        ? true
        : false;

    const RedirectToBrowser = ({ url }) => {
        useEffect(() => {
            // Controlla se l'utente è su Instagram
            if (navigator.userAgent.match(/Instagram/i)) {
                // Tenta di aprire il link nel browser predefinito
                window.location.href = url;
            } else {
                // Altrimenti, reindirizza direttamente
                //window.location.href = url;
            }
        }, [url]);

        return (
            <div>
                <p>
                    Reindirizzamento in corso... Se non vieni reindirizzato,{" "}
                    <a href={url}>clicca qui</a>.
                </p>
            </div>
        );
    };

    return (
        <Provider state={state} dispatch={dispatch}>
            <div className="flex flex-col min-h-[100lvh]">
                <Toaster position="top-center" richColors />
                <Navbar />
                <div
                    className={`${
                        !fullScreen &&
                        "mt-navbar-mobile md:mt-navbar-web mb-bottombar-phone md:mb-0"
                    } ${
                        ((logged &&
                            ["chisiamo", "localheroes"].includes(
                                window.location.pathname.split("/")[1]
                            )) ||
                            (!logged &&
                                ["", "localheroes"].includes(
                                    window.location.pathname.split("/")[1]
                                ))) &&
                        "mt-[5rem]"
                    } flex-1  overflow-auto`}
                >
                    {loaded && (
                        <div
                            className={`${!fullScreen ? "md:block" : "hidden"}`}
                        >
                            <div className="md:block hidden">
                                <Sidebar />
                            </div>
                        </div>
                    )}
                    {loaded ? (
                        <div
                            className={`${
                                !fullScreen && "h-full px-4 md:ml-sidebar"
                            }`}
                        >
                            <BrowserRouter>
                                {logged ? (
                                    <Routes>
                                        <Route path="/" element={<Home />} />
                                        <Route
                                            path="/prodotto/:id"
                                            element={<ProdottoApertoP />}
                                        />
                                        <Route
                                            path="/dashboard/:id"
                                            element={<ProdottoAperto />}
                                        />
                                        <Route
                                            path="/chat/:chat"
                                            element={<Chat />}
                                        />
                                        <Route
                                            path="/chat"
                                            element={<Chat />}
                                        />
                                        <Route
                                            path="/notifications"
                                            element={<Notifications />}
                                        />
                                        <Route
                                            path="/favourites"
                                            element={<Preferiti />}
                                        />

                                        <Route
                                            path="/profilo"
                                            element={<Profilo />}
                                        />
                                        <Route
                                            path="/profilo/:id"
                                            element={<Profili />}
                                        />
                                        <Route
                                            path="/search"
                                            element={<Search />}
                                        />

                                        <Route
                                            path="/logout"
                                            element={<Exit />}
                                        />
                                        <Route
                                            path="/registrato"
                                            element={<Registrato />}
                                        />
                                        <Route
                                            path="/faq"
                                            element={<Funziona />}
                                        />
                                        <Route
                                            path="/chisiamo"
                                            element={<Facciata />}
                                        />
                                        <Route
                                            path="/register"
                                            element={<Registration />}
                                        />
                                        <Route
                                            path="/localheroes"
                                            element={<LocalHeroes />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate to="/" replace />
                                            }
                                        />
                                    </Routes>
                                ) : (
                                    <Routes>
                                        <Route
                                            path="/"
                                            element={<Facciata />}
                                        />
                                        <Route
                                            path="/webapp"
                                            element={<Home />}
                                        />
                                        <Route
                                            path="/localheroes"
                                            element={<LocalHeroes />}
                                        />

                                        <Route
                                            path="/login"
                                            element={<Login />}
                                        />
                                        <Route
                                            path="/register"
                                            element={<Registration />}
                                        />
                                        <Route
                                            path="/faq"
                                            element={<Funziona />}
                                        />
                                        <Route
                                            path="/chisiamo"
                                            element={<Chisiamo />}
                                        />
                                        <Route
                                            path="/search"
                                            element={<Search />}
                                        />
                                        <Route
                                            path="/problemi"
                                            element={<Problemi />}
                                        />
                                        <Route
                                            path="/recupero"
                                            element={<RecuperoPassword />}
                                        />
                                        <Route
                                            path="/prodotto/:id"
                                            element={<ProdottoApertoP />}
                                        />
                                        <Route
                                            path="/registrato"
                                            element={<Registrato />}
                                        />
                                        <Route
                                            path="*"
                                            element={
                                                <Navigate to="/login" replace />
                                            }
                                        />
                                    </Routes>
                                )}
                            </BrowserRouter>
                            <Footer />
                            {logged && (
                                <DrawersPrimoLogin
                                    onFinished={() =>
                                        getHomeTutorial(isMobile).drive()
                                    }
                                />
                            )}
                        </div>
                    ) : (
                        <div className="h-[100vh]">
                            <Caricamento />
                        </div>
                    )}
                </div>
                <div className="fixed md:hidden block z-50 w-full bottom-0 left-0">
                    <SidebarPhone />
                </div>
            </div>
        </Provider>
    );
}

export default App;

const Redirect = ({ path }) => {
    window.location.href = path;
    return <Navigate to={path} replace />;
};
