import Moment from "react-moment";
import cats from "../../../Components/Molecules/Categorie/cats";
import useUserStore from "../../../stores/userStore";

import IconDistanza from "../../../assets/Icons/Distanza"
import IconDisponibilita from '../../../assets/Icons/Calendario';
import IconaAccettabile from "../../../assets/Icons/Stati/Accettabile";
import IconaBuono from "../../../assets/Icons/Stati/Buono";
import IconaComeNuovo from "../../../assets/Icons/Stati/ComeNuovo";
import IconaDaAggiustare from "../../../assets/Icons/Stati/DaAggiustare";
import { FaRegClock } from "react-icons/fa";

export const MicroCategoriaRegalo = ({ microCategoria }) => {
    return (
        <>
            <div className="w-8 text-verdeScuro">
                {microCategoria &&
                    cats.filter((item) =>
                        item.micro.includes(microCategoria)
                    )[0].img}
            </div>
            <p className="font-medium">{microCategoria}</p>
        </>
    )
}

export const Distanza = ({ distanza }) => {
    return (
        <p className="font-light flex items-center space-x-2 md:px-6 md:border-r border-verdeScuro">
            <span className="w-5 mr-2 fill-verdeScuro">
                <IconDistanza />
            </span>{" "}
            {distanza} Km
        </p>
    )
}

export const Disponibilita = ({ disponibilita }) => {
    return (
        <p className="font-light items-center md:border-r border-verdeScuro flex pr-6 ">
            <span className="w-5 mr-2 fill-verdeScuro">
                <IconDisponibilita />
            </span>{" "}
            {disponibilita ? disponibilita : "Mancante"}
        </p>
    )
}

export const Condizioni = ({ condizioni }) => {
    return (
        <p className="flex font-light space-x-2 items-center md:px-6 pr-6 md:border-r border-verdeScuro">
            <span className="text-verdeScuro mr-2 w-5">
                {" "}
                {condizioni === "Accettabile" ? (
                    <IconaAccettabile />
                ) : condizioni === "Buone" ? (
                    <IconaBuono />
                ) : condizioni === "Come nuovo" ? (
                    <IconaComeNuovo />
                ) : (
                    <IconaDaAggiustare />
                )}
            </span>
            {condizioni ? condizioni : "Stato Mancante"}
        </p>
    )
}

export const TempoPubblicazione = ({ createdAt }) => {
    return (
        <p className="font-light flex space-x-2 items-center md:px-6 pr-6 ">
            <span className="text-verdeScuro text-xl">
                {" "}
                <FaRegClock />
            </span>

            <Moment fromNow locale="it">
                {createdAt}
            </Moment>
        </p>
    )
}

export const DescrizioneRegalo = ({ descrizione }) => {
    return (
        <>
            <p className="md:text-xl text-lg">Descrizione</p>
            <p className="font-light md:mt-5 mt-2 text-sm">
                {descrizione}
            </p>
        </>
    )
}

const CardInfoRegalo = ({ titolo, microCategoria, distanza, disponibilita, condizioni, createdAt, descrizione, className }) => {
    const containerClassName = "bg-white md:text-md text-sm md:mt-5 mt-2 md:p-3 p-2 rounded-lg";
    const logged = useUserStore((state) => state.isLogged);

    return (
        <div className={className || containerClassName}>
            {titolo && <p className="md:text-2xl text-xl font-semibold">
                {titolo}
            </p>}
            <div className="flex space-x-3 items-center md:mt-5 mt-3">
                <MicroCategoriaRegalo microCategoria={microCategoria} />
            </div>
            <div className="flex flex-wrap mt-5 gap-y-2 gap-x-2">
                <Disponibilita disponibilita={disponibilita} />
                {logged && distanza && (
                    <Distanza distanza={distanza} />
                )}
                <Condizioni condizioni={condizioni} />
                <TempoPubblicazione createdAt={createdAt} />
            </div>
            <div className="w-full h-[2px] my-2 bg-verdeScuro/10" />
            <DescrizioneRegalo descrizione={descrizione} />
        </div>
    )
}

export default CardInfoRegalo;