import {
    FiEdit,
    FiChevronDown,
    FiTrash,
    FiShare,
    FiPlusSquare,
} from "react-icons/fi";
import { IoIosClose } from "react-icons/io";
import {
    AnimatePresence,
    motion,
} from "framer-motion";
import { useEffect, useState } from "react";
import ReactGoogleAutocomplete from "react-google-autocomplete";
import BottoneGrigio from "../../Atoms/Bottoni/BottoneGrigio";
import useMeasure from "react-use-measure";
import { IoClose } from "react-icons/io5";

const DropdownLocation = ({
    isOpen,
    setIsOpen,
    opennth,
    setCoordinate,
    coordinate,
    cancellaFiltro,
}) => {
    const [open, setOpen] = useState(false);
    useEffect(() => {
        for (let e of document.querySelectorAll(
            'input[type="range"].slider-progress'
        )) {
            e.style.setProperty("--value", e.value);
            e.style.setProperty("--min", e.min === "" ? "0" : e.min);
            e.style.setProperty("--max", e.max === "" ? "100" : e.max);
            e.addEventListener("input", () =>
                e.style.setProperty("--value", e.value)
            );
        }
    }, []);
    useEffect(() => {
        if (isOpen) {
            setOpen(true);
        } else {
            setOpen(false);
        }
    }, [isOpen]);

    const handleClick = () => {
        setIsOpen(opennth);
    };

    return (
        <div className="flex items-center ">
            <motion.div
                animate={open ? "open" : "closed"}
                className="z-50 relative"
            >
                <button
                    onClick={() => handleClick()}
                    className="h-full min-w-40 px-2 border border-[#e2e2e2] flex items-center justify-evenly py-2 cursor-pointer rounded-md text-verdeScuro bg-[#e2e2e2] hover:bg-[#e2e2e2] transition-colors"
                >
                    <span className="font-medium text-sm">
                        <nobr>
                            {coordinate.citta && coordinate.citta.length > 12
                                ? coordinate.citta.slice(0, 12) + "..."
                                : coordinate.citta}
                         </nobr>
                    </span>
                    <span className="font-medium pl-2 text-sm">
                        <nobr> {coordinate.range} km</nobr>
                    </span>
                    <motion.span className="md:block hidden" variants={iconVariants}>
                        <FiChevronDown />
                    </motion.span>
                </button>

                <div className="md:flex hidden">
                    <motion.div
                        initial={wrapperVariants.closed}
                        variants={wrapperVariants}
                        transition={{ duration: 0.001 }}
                        style={{ originY: "top", translateX: "0%" }}
                        className="flex left-0 md:w-96 w-[90vw] h-72 z-50 flex-col gap-2 p-2 rounded-lg bg-white shadow-xl absolute top-[120%] md:left-0 md:translate-x-0  overflow-hidden"
                    >
                        <Location
                            setOpen={setOpen}
                            coordinate={coordinate}
                            setCoordinate={setCoordinate}
                            cancellaFiltro={cancellaFiltro}
                            handleClick={handleClick}
                        />
                    </motion.div>
                </div>
                
            </motion.div>
        </div>
    );
};


const Location = ({
    isOpen,
    setOpen,
    setCoordinate,
    handleClick,
    cancellaFiltro,
    coordinate,
}) => {
    const [range, setRange] = useState(100);
    const setCoordinates = async (place, type) => {
        try {
            const lat = await place.geometry.location.lat();
            const lng = await place.geometry.location.lng();
            console.log(lat, lng, place.address_components[0].long_name);
            setCoordinate({
                ...coordinate,
                lat: lat,
                lng: lng,
                citta: place.address_components[0].long_name,
            });
        } catch (error) {
            console.log(error);
        }
    };




    return (
        <AnimatePresence>
            <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.001 }}
                className="  inset-0 z-50 grid place-items-center  "
            >
                <motion.div
                    initial={{ scale: 0, rotate: "12.5deg" }}
                    animate={{ scale: 1, rotate: "0deg" }}
                    transition={{ duration: 0.001 }}
                    exit={{ scale: 0, rotate: "0deg" }}
                    onClick={(e) => e.stopPropagation()}
                    className=" p-4  overflow-hidden  "
                >
                    <div className="w-full flex flex-col justify-center items-center gap-5">
                        <ReactGoogleAutocomplete
                            apiKey={process.env.REACT_APP_MAPS_API_KEY}
                            style={{
                                width: "300px",
                                height: "40px",
                            }}
                            className="appearance-none flex justify-center items-center ring-1 ring-transparent bg-background rounded-lg p-2 focus:ring-1 col-span-5 focus:ring-verde"
                            onPlaceSelected={(place) => {
                                //console.log(place);
                                //setCoordinates(place);
                                try {
                                    //console.log(place);
                                    setTimeout(() => {
                                        setCoordinates(place);
                                    }, 500);
                                } catch (err) {
                                    setTimeout(() => {
                                        setCoordinates(place);
                                    }, 1500);
                                    console.log(err);
                                }
                            }}
                            placeholder="Comune, Provincia, Regione"
                            options={{
                                types: ["(cities)"],
                                componentRestrictions: { country: "it" },
                            }}
                            language="it"
                        />
                        <div
                            onClick={(e) => e.stopPropagation()}
                            className="flex flex-col justify-center items-center w-full gap-3 col-span-5"
                        >
                            <div className="flex w-full justify-between">
                                <span>Entro</span> <span>{range} km</span>
                            </div>
                            <input
                                value={range}
                                onChange={(e) => {
                                    setRange(e.currentTarget.value);
                                }}
                                min={1}
                                max={400}
                                type="range"
                                className="istyle bg-transparent slider-progress md:w-[300px] w-full "
                            />
                            <div className="flex w-full text-sm text-grigio justify-between">
                                <span>1 km</span> <span>400 km</span>
                            </div>
                        </div>
                    </div>
                    <div className="justify-self-center mt-10">
                        <BottoneGrigio
                            text={"Conferma"}
                            onclick={() => {
                                handleClick();
                                setCoordinate({ ...coordinate, range: range });
                            }}
                        />
                        <p onClick={()=>cancellaFiltro()} className="text-sm text-center mt-2 text-verdeScuro underline cursor-pointer">Cancella filtro</p>
                    </div>
                </motion.div>
            </motion.div>
        </AnimatePresence>
    );
};

export default DropdownLocation;

const wrapperVariants = {
    open: {
        scaleY: 1,
    },
    closed: {
        scaleY: 0,
    },
};

const iconVariants = {
    open: { rotate: 180 },
    closed: { rotate: 0 },
};
