import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";
import { getAuth, FacebookAuthProvider } from "firebase/auth";
//import { getMessaging, getToken } from "firebase/messaging";
//import { useEffect } from "react";

const firebaseConfig = {
    apiKey: process.env.REACT_APP_API_KEY,
    authDomain: "togiftit.com",
    projectId: process.env.REACT_APP_PROJECT_ID,
    storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
    messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
    appId: process.env.REACT_APP_APP_ID,
};

const firebaseConfigTest = {
    apiKey: "AIzaSyAZjcZxwRpD4MYQ5hIPkMTqz8lKfYW5rTs",
    authDomain: "test-togiftit.firebaseapp.com",
    projectId: "test-togiftit",
    storageBucket: "test-togiftit.appspot.com",
    messagingSenderId: "564666700301",
    appId: "1:564666700301:web:f95c634f5da892fd0dbb44"
};

const app = initializeApp(process.env.REACT_APP_TEST === "true" ? firebaseConfigTest : firebaseConfig);
const storage = getStorage(app, process.env.REACT_APP_BUCKET_URL);


//export const messaging = getMessaging(app);
export const db = getFirestore(app);
export const auth = getAuth(app);
export const facebook = new FacebookAuthProvider();
export default storage;
