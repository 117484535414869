import React from "react";

function Cerchio12() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 945.91 880.33">
      <path
        d="M471.89 0C280.55 0 110.1 65.46 0 167.46v545.42c110.1 102 280.55 167.46 471.89 167.46s364-66.31 474.02-169.45V169.45C835.89 66.32 664.47 0 471.89 0zm0 613.21c-205.78 0-372.6-114.15-372.6-254.97s166.82-254.96 372.6-254.96 372.6 114.15 372.6 254.97-166.82 254.97-372.6 254.97z"
        className="fill-[#f5f3f6]"
        data-name="Livello_1"
      ></path>
    </svg>
  );
}

export default Cerchio12;