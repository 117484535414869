import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import useFirstLoginStore from '../../../stores/firstLoginStore'
import BottoneGrigio from "../Bottoni/BottoneGrigio";
import { IoIosClose } from "react-icons/io";
import Icon from "../../../assets/PNG/PIU PUNTITavola disegno 1_3x.png";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import DragCloseDrawer from "../Drawers/DragCloseDrawer";
import Punti from "../../../assets/Punti";

const CodiceBenvenuto = ({ onClose }) => {
    const codiceBenvenuto = useFirstLoginStore((state) => state.codiceBenvenuto);
    const setCodiceBenvenuto = useFirstLoginStore((state) => state.setCodiceBenvenuto);
    const isCodiceBenvenutoValido = useFirstLoginStore((state) => state.codiceBenvenutoValido);
    const checkCodiceBenvenuto = useFirstLoginStore((state) => state.checkCodiceBenvenuto);
    const errore = useFirstLoginStore((state) => state.errore);

    const [isOpen, setIsOpen] = useState(true);
    const device = useDeviceDetection();

    useEffect(() => {
        if (isCodiceBenvenutoValido === true) {
            handleClose();
        }
    }, [isCodiceBenvenutoValido]);

    const handleClose = async (e) => {
        setIsOpen(false);
        onClose(e);
    };

    const handleCodiceBenvenuto = async (e) => {
        // TODO: backend call to set benvenuto
        alert("Codice Benvenuto inserito correttamente");
        onClose(e);
    };

    const welcomeButton = (
        <div className="flex justify-center mt-8">
            <div className="w-full flex flex-col md:flex-row-reverse md:w-auto gap-2 md:gap-0">
                {/* TODO: cambiare con Bottone.jsx della PR#14 */}
                <button
                    type="button"
                    disabled={!codiceBenvenuto}
                    onClick={checkCodiceBenvenuto}
                    className={`py-2 px-4 w-full min-w-32 h-12 text-center 
                    hover:text-verdeScuro text-white border border-verde hover:bg-verdeScuro/30 hover:border-verdeScuro/30 bg-verde focus:ring-4 focus:outline-none focus:ring-verdeScuro/30 
                    font-semibold rounded-2xl text-xl md:text-lg flex items-center justify-center ${!codiceBenvenuto ? 'cursor-not-allowed opacity-50' : ''}`}
                >
                    Inserisci codice
                </button>
                <button
                    type="button"
                    onClick={(e) => handleClose(e)}
                    className="py-2 px-4 w-full min-w-32 h-12 text-center 
                    hover:text-verdeScuro text-verdeScuro 
                    font-semibold rounded-2xl text-xl md:text-lg flex items-center justify-center"
                >
                    No, non ho un codice
                </button>
            </div>
        </div>
    )

    const welcomeMessage = (
        <div className="w-full flex px-4 flex-col items-center text-center gap-5">
            <h1 className="text-4xl font-malik text-center md:text-3xl md:text-start text-verdeScuro font-bold px-4">
                Hai un codice benvenuto?
            </h1>

            <div className="flex flex-col md:flex-row-reverse">
                <div className="flex flex-col md:flex-[2] gap-5">
                    <div className="flex flex-col px-6 md:px-0 gap-2 text-lg">
                        <p className="">Se hai un codice benvenuto inseriscilo per ricevere</p>
                        <div className="flex font-semibold items-center justify-center gap-2">
                            <div className="w-6">
                                <Punti />
                            </div>
                            <p>Punti Gift aggiuntivi</p>
                        </div>
                    </div>

                    <input
                        type="text"
                        placeholder="#BENVENUTOGIFTER"
                        className="py-2 px-4 w-full min-w-32 h-12 text-start text-verdeScuro border border-verdeScuro font-semibold rounded-2xl text-xl flex items-center justify-center"
                        onChange={(e) => setCodiceBenvenuto(e.target.value)}
                    />
                    {(codiceBenvenuto || errore)  && !isCodiceBenvenutoValido && <p className="text-rosso text-sm -mt-4">{errore}</p>}
                    {device !== 'Mobile' && welcomeButton}
                </div>

                <div className="w-full md:flex-[2]">
                    <img src={Icon} alt="Codice benvenuto" />
                </div>
            </div>
        </div>
    )
    
    const mobileBody = (
        <DragCloseDrawer fullscreen={device === 'Mobile' ? true : false} open={isOpen}>
            <div className="flex flex-col relative h-[120vh] max-w-xl bg-white rounded-lg overflow-hidden ">
                {welcomeMessage}
                {welcomeButton}
            </div>
        </DragCloseDrawer>
    )
    const desktopBody = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // onClick={() => handleClose(false)}
            className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
        >
            <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="relative py-4 pb-8 md:pb-0 pr-8 w-1/2 bg-white rounded-lg overflow-hidden"
            >
                <div className="relative py-4 pb-8 md:pb-0 pr-8 max-w-3xl bg-white rounded-lg overflow-hidden">
                    {welcomeMessage}
                    {device == 'Mobile' && welcomeButton}
                </div>
            </motion.div>
        </motion.div>
    )
    const body = device === "Mobile" ? mobileBody : desktopBody

    return (
        <AnimatePresence>
            {isOpen && body}
        </AnimatePresence>
    );
};

export default CodiceBenvenuto;
