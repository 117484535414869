import {
    addDoc,
    collection,
    doc,
    limit,
    orderBy,
    query,
    runTransaction,
    setDoc,
    getDoc,
    updateDoc,
    increment,
} from "firebase/firestore";
import { auth, db } from "../firebase";
import { postNotification } from "./NotificationsUtils";

/**
 * @typedef {Object} Chat
 * @property {string} lastMessage
 * @property {string} completeName
 * @property {string} itemName
 * @property {string} itemId
 * @property {boolean} isBooking
 * @property {boolean} bookingAccepted
 * @property {boolean} confirmedByOwner
 * @property {boolean} confirmedByReceiver
 * @property {string} receiver
 * @property {string} owner
 * @property {string} userId
 * @property {boolean} canceled
 * @property {string} id
 */

/**
 *
 * @param {string} receiver
 * @param {string} owner
 * @param {string} itemId
 * @returns {string}
 */
export function getChatFolder(receiver, owner, itemId) {
    return receiver + ":" + owner + ":" + itemId;
}

export function lastMessageQuery(chatId) {
    return query(
        collection(db, "new_chats/" + chatId + "/messages"),
        orderBy("createdAt", "desc"),
        limit(1)
    );
}

export async function setReadMessage(messageRef) {
    await updateDoc(messageRef, {
        read: true,
    });
}

export function renderTime(rawTime) {
    const time = rawTime.toDate();
    const hours = time.getHours();
    const minutes = time.getMinutes();
    return hours + ":" + (minutes < 10 ? "0" + minutes : minutes);
}

export function renderDate(rawTime) {
    const time = rawTime.toDate();
    const day = time.getDate();
    const month = time.getMonth() + 1;
    const year = time.getFullYear();
    return (
        (day < 10 ? "0" + day : day) +
        "/" +
        (month < 10 ? "0" + month : month) +
        "/" +
        year
    );
}

/**
 * @param {string} ownerId - The owner of the item
 * @param {*} item The item the chat refers to
 * @param {boolean} isBooking If true, the caller is requesting to book the item from user, if false, the caller is just requesting info
 */
export async function initChat(ownerId, item, receiverCompleteName, ownerCompleteName, isBooking = false) {
    const chatId = getChatFolder(auth.currentUser.uid, ownerId, item.id);

    const bookingExists = (await getDoc(doc(db, "new_chats", chatId))).exists();

    await setDoc(
        doc(
            db,
            "new_chats",
            chatId
        ),
        {
            participants: [auth.currentUser.uid, ownerId],
            itemName: item.titolo,
            itemId: item.id,
            owner: ownerId,
            receiver: auth.currentUser.uid,
            isBooking: isBooking,
            bookingAccepted: false,
            canceled: false,
            confirmedByOwner: false,
            confirmedByReceiver: false,
        },
        { merge: true }
    );

    if (bookingExists) {
        return;
    }

    let firstMessageContent;

    if (isBooking) {
        await postNotification(ownerId, {
            title: receiverCompleteName,
            content: "Mi sono prenotato al tuo regalo: " + item.titolo,
            sender: auth.currentUser.uid,
            type: "prenotazione",
            link: "/chat/" + chatId,
        });
        firstMessageContent = `Ciao ${ownerCompleteName}, mi sono prenotato al tuo regalo, mi sarebbe molto utile e mi piacerebbe dargli una seconda vita.`;
    }
    else {
        firstMessageContent = `Ciao ${ownerCompleteName}, mi sono prenotato al tuo regalo.`;
    }

    sendMessage(chatId, ownerId, firstMessageContent, null);
}

/**
 * Sets a chat status as canceled.
 * It can be invoked both by the owner of the item and the receiver.
 * The chat is not deleted.
 * @param {string} owner
 * @param {string} receiver
 * @param {string} item
 */
export async function cancelChat(owner, receiver, item) {
    await runTransaction(db, async (transaction) => {
        const giftRef = doc(db, "regali", item);

        const gift = await transaction.get(giftRef);

        transaction.update(doc(db, "new_chats", getChatFolder(receiver, owner, item)), {
            canceled: true
        });

        if (gift.data().assegnato) {
            transaction.update(giftRef, {
                assegnato: null
            });
            transaction.update(doc(db, "users", owner), {
                punti: increment(-100)
            });
            transaction.update(doc(db, "users", receiver), {
                adozioni: increment(-1)
            });
            if (owner === auth.currentUser.uid) {
                transaction.update(doc(db, "users", receiver), {
                    punti: increment(100)
                });
            }
        }
    });
}

/**
 *
 * @param {string} owner
 * @param {string} receiver
 * @param {string} item
 * @param {string} receiverName
 * @param {Chat|null} chat
 */
export async function confirmItemReceived(
    owner,
    receiver,
    item,
    receiverName,
    chat = null
) {

    await runTransaction(db, async (transaction) => {
        const giftRef = doc(db, "regali", item);

        const gift = (await transaction.get(giftRef)).data();

        if (gift.consegnato) {
            const prenotati = gift.prenotati || [];

            prenotati.forEach(prenotatoId => {
                const prenotatoRef = doc(db, "users", prenotatoId);
                transaction.update(prenotatoRef, {
                    punti: increment(100)
                });
            });
        }

        transaction.update(doc(db, "new_chats", getChatFolder(receiver, owner, item)), {
            confirmedByReceiver: true
        });
        transaction.update(giftRef, {
            ricevuto: true
        });
    });
    
    await postNotification(
        owner,
        {
            title: receiverName,
            content: "Ho ricevuto il tuo regalo!",
            sender: receiver,
            
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

/**
 *
 * @param {string} owner
 * @param {string} receiver
 * @param {string} item
 * @param {string} ownerName
 * @param {Chat|null} chat
 */
export async function confirmItemDelivered(
    owner,
    receiver,
    item,
    ownerName,
    chat = null
) {
    await runTransaction(db, async (transaction) => {
        const giftRef = doc(db, "regali", item);

        const gift = (await transaction.get(giftRef)).data();

        if (gift.ricevuto) {
            const prenotati = gift.prenotati || [];

            prenotati.forEach(prenotatoId => {
                const prenotatoRef = doc(db, "users", prenotatoId);
                transaction.update(prenotatoRef, {
                    punti: increment(100)
                });
            });
        }

        transaction.update(doc(db, "new_chats", getChatFolder(receiver, owner, item)), {
            confirmedByOwner: true
        });
        transaction.update(giftRef, {
            consegnato: true
        });
    });

    await postNotification(
        receiver,
        {
            title: ownerName,
            content: "Ho consegnato il tuo regalo.",
            sender: owner,
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

export async function undoItemReceived(
    owner,
    receiver,
    item,
    receiverName,
    chat = null
) {
    await runTransaction(db, async (transaction) => {
        transaction.update(doc(db, "new_chats", getChatFolder(receiver, owner, item)), {
            confirmedByReceiver: false
        });
        transaction.update(doc(db, "regali", item), {
            ricevuto: false
        });
    });
    
    await postNotification(
        owner,
        {
            title: receiverName,
            content: "Ho commesso un errore... Non ho ricevuto il tuo regalo!",
            sender: receiver,
            
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

export async function undoItemDelivered(
    owner,
    receiver,
    item,
    ownerName,
    chat = null
) {
    await runTransaction(db, async (transaction) => {
        transaction.update(doc(db, "new_chats", getChatFolder(receiver, owner, item)), {
            confirmedByOwner: false
        });
        transaction.update(doc(db, "regali", item), {
            consegnato: false
        });
    });

    await postNotification(
        receiver,
        {
            title: ownerName,
            content: "Ho commesso un errore... Non ho consegnato il tuo regalo!",
            sender: owner,
            link: "/chat/" + getChatFolder(receiver, owner, item),
        },
        chat
    );
}

/**
 * @param {string} chatId
 * @param {string} sendTo
 * @param {string} content
 * @param {string|null} imageUrl
 * @param {boolean} isAutomatic
 */
export async function sendMessage(
    chatId,
    sendTo,
    content,
    imageUrl,
    isAutomatic = false
) {
    let messageData = {};

    console.log(chatId);
    console.log(sendTo);

    messageData = {
        ...messageData,
        sendBy: auth.currentUser.uid,
        sendTo: sendTo,
        content: content,
        read: false,
        //TODO: Message timestamp should be set server-side
        createdAt: new Date(),
        imageUrl: imageUrl,
        isAuto: isAutomatic,
    };

    await addDoc(
        collection(db, "new_chats/" + chatId + "/messages"),
        messageData
    );
}