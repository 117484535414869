import React from "react";

function Cerchio22() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 948.07 1205.04">
      <path
        d="M472.97 0C281.19 0 110.35 89.6 0 229.22v746.59c110.35 139.62 281.19 229.22 472.97 229.22s364.83-90.77 475.1-231.95V231.95C837.8 90.78 665.99 0 472.97 0zm0 839.39c-206.25 0-373.45-156.26-373.45-349.01s167.2-349.01 373.45-349.01 373.45 156.26 373.45 349.01-167.2 349.01-373.45 349.01z"
        className="fill-[#f5f3f6]"
        data-name="Livello_1"
      ></path>
    </svg>
  );
}

export default Cerchio22;