import { AnimatePresence, motion } from "framer-motion";
import { useEffect, useRef, useState } from "react";
import { IoIosClose } from "react-icons/io";
import BottoneRosso from "../Bottoni/BottoneRosso";
import BottoneVerde2 from "../Bottoni/BottoneRosso";
import FileUploadBtn from "../Bottoni/FileUploadBtn";
import CategorieDropdownRegalo from "../../Molecules/Dropdown/CategorieDropdownRegalo";
import StaggeredDropDownRegalo from "../../Molecules/Dropdown/StaggeredDropDownRegalo";
import { addDoc, collection, doc, updateDoc } from "firebase/firestore";
import { db } from "../../../firebase";
import { uploadImage } from "../../../utils/ImageUtils";
import useUserStore from "../../../stores/userStore";

const ModRegalo = ({ regalo }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [distance, setDistance] = useState(0);

    return (
        <div className="h-[100%]">
            <button
                type="button"
                onClick={() => setIsOpen(true)}
                className="bg-verdeScuro/50 px-3 py-2 text-white font-semibold rounded-lg"
            >
                Modifica
            </button>
            <SpringModal
                regalo={regalo}
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                distance={distance}
                setDistance={setDistance}
            />
        </div>
    );
};

const SpringModal = ({ isOpen, setIsOpen, regalo }) => {
    const user = useUserStore((state) => state.user);
    const [titolo, setTitolo] = useState(regalo.titolo);
    const [descrizione, setDescrizione] = useState(regalo.descrizione);
    const [imagePreview, setImagePreview] = useState(regalo.img[0]);
    const [mainImageFile, setMainImageFile] = useState(null);
    const [additionalImages, setAdditionalImages] = useState([]);
    const [additionalImageFiles, setAdditionalImageFiles] = useState([
        null,
        null,
        null,
        null,
    ]);
    const [categoria, setCategoria] = useState(regalo.microcategoria);
    const [selectedCondizioni, setSelectedCondizioni] = useState(
        regalo.condizioni
    );
    const [disponibilita, setDisponibilita] = useState(regalo.disponibilita);
    const [loading, setLoading] = useState(false);
    let fileButton = useRef(null);
    const [next, setNext] = useState(1);
    const [charCount, setCharCount] = useState(titolo.length);
    const [charDCount, setCharDCount] = useState(descrizione.length);

    useEffect(() => {
        const images = regalo.img
            .slice(1, 5)
            .map((img) => (img === "" ? null : img));
        // Aggiungi null fino a raggiungere 5 elementi
        while (images.length < 4) {
            images.push(null);
        }
        setAdditionalImages(images);
        console.log("Additional Images: ", images); // Questo dovrebbe mostrare l'array trasformato e riempito con null
    }, []);

    const handleTitoloChange = (event) => {
        setCharCount(
            event.target.value.length > 50
                ? "Titolo troppo lungo!"
                : event.target.value.length
        );
    };

    const handleDescChange = (event) => {
        setCharDCount(
            event.target.value.length > 700
                ? "Descrizione troppo lunga!"
                : event.target.value.length
        );
    };

    const onPhotoChoose = (e) => {
        const file = e.target.files[0];
        setMainImageFile(file);
        const imageUrl = URL.createObjectURL(file);
        setImagePreview(imageUrl);
    };

    const onAdditionalPhotoChoose = (e, index) => {
        const file = e.target.files[0];
        const newAdditionalImages = [...additionalImages];
        newAdditionalImages[index] = URL.createObjectURL(file);

        const newAdditionalImageFiles = [...additionalImageFiles];
        newAdditionalImageFiles[index] = file;

        setAdditionalImages(newAdditionalImages);
        setAdditionalImageFiles(newAdditionalImageFiles);
    };

    const removeAdditionalPhoto = (index) => {
        const newAdditionalImages = [...additionalImages];
        newAdditionalImages[index] = null;

        const newAdditionalImageFiles = [...additionalImageFiles];
        newAdditionalImageFiles[index] = null;

        setAdditionalImages(newAdditionalImages);
        setAdditionalImageFiles(newAdditionalImageFiles);
    };

    const handleSubmit = async () => {
        try {
            setLoading(true);
            if (!mainImageFile && !imagePreview)
                throw new Error("Immagine principale non definita");
            if (
                !titolo ||
                !descrizione ||
                !categoria ||
                !user.uid ||
                !disponibilita ||
                !selectedCondizioni
            )
                throw new Error("Dati mancanti");

            const mainImage = mainImageFile
                ? await uploadImage(mainImageFile)
                : imagePreview;

            let additionalImagesPromises = [];
            additionalImageFiles.forEach((file, index) => {
                if (file) {
                    additionalImagesPromises.push(uploadImage(file));
                } else if (additionalImages[index]) {
                    additionalImagesPromises.push(
                        Promise.resolve(additionalImages[index])
                    );
                }
            });

            let updatedAdditionalImages = await Promise.all(
                additionalImagesPromises
            );
            updatedAdditionalImages.unshift(mainImage);

            const regaloRef = doc(db, "regali", regalo.id);
            await updateDoc(regaloRef, {
                titolo: titolo,
                descrizione: descrizione,
                microcategoria: categoria,
                condizioni: selectedCondizioni,
                disponibilita: disponibilita,
                img: updatedAdditionalImages,
                updatedAt: new Date().toISOString(),
            });
            setIsOpen(false);
            console.log("Documento aggiornato con ID: ", regalo.id);
        } catch (error) {
            console.error("Errore durante l'aggiornamento: ", error);
        }
    };

    const [dropdownIsOpen, setdropdownIsOpen] = useState(0);
    const [dropdown2IsOpen, setdropdown2IsOpen] = useState(0);
    const handleSetIsOpen = (dropdownNumber) => {
        setdropdownIsOpen((prevIsOpen) =>
            prevIsOpen === dropdownNumber ? 0 : dropdownNumber
        );
    };
    const handleSetIsOpen2 = (dropdownNumber) => {
        setdropdown2IsOpen((prevIsOpen) =>
            prevIsOpen === dropdownNumber ? 0 : dropdownNumber
        );
    };

    return (
        <AnimatePresence>
            {isOpen && (
                <motion.div
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    onClick={() => setIsOpen(false)}
                    className="bg-slate-900/20 backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
                >
                    <motion.div
                        initial={{ scale: 0, rotate: "12.5deg" }}
                        animate={{ scale: 1, rotate: "0deg" }}
                        exit={{ scale: 0, rotate: "0deg" }}
                        transition={{ duration: 0.05 }}
                        onClick={(e) => e.stopPropagation()}
                        className={`${
                            next === 1
                                ? "w-[60vw]"
                                : next === 2
                                ? "w-[80vw]"
                                : "w-[60vw]"
                        } xl:h-[90vh] bg-white rounded-lg overflow-auto shadow-lg flex flex-col transition-all duration-300`}
                    >
                        <div className="flex items-center justify-between shadow-md rounded-t-lg w-full p-4">
                            <h1 className="font-bold text-xl ">
                                Modifica il regalo
                            </h1>
                            <IoIosClose
                                onClick={() => {
                                    setIsOpen(false);
                                    setNext(0);
                                    setImagePreview(null);
                                    setMainImageFile(null);
                                    setAdditionalImages(regalo.img.slice(1, 5));
                                    setAdditionalImageFiles([
                                        null,
                                        null,
                                        null,
                                        null,
                                    ]);
                                }}
                                className="w-5 h-5 bg-verdeScuro hover:bg-verde fill-white rounded-full cursor-pointer"
                            />
                        </div>
                        <div className="flex flex-col justify-center items-center h-full p-2">
                            <div
                                className={`${
                                    next === 1 ? "block" : "hidden"
                                } grid grid-cols-2 w-full  h-full gap-4`}
                            >
                                <div className="p-2 w-full relative rounded-lg h-full flex justify-center items-center">
                                    {imagePreview && (
                                        <img src={imagePreview} alt="" />
                                    )}
                                    <button
                                        onClick={() => {
                                            imagePreview != null &&
                                                setImagePreview(null) &&
                                                setMainImageFile(null);
                                        }}
                                        className={`${
                                            imagePreview ? "block" : "hidden"
                                        } absolute top-2 right-2 bg-red-600 text-white rounded-full p-1`}
                                    >
                                        <IoIosClose className="w-5 h-5" />
                                    </button>
                                    <div
                                        className={`${
                                            imagePreview != null
                                                ? "hidden"
                                                : "flex"
                                        } justify-center items-center w-full `}
                                    >
                                        <BottoneRosso
                                            text={"Carica foto"}
                                            onclick={(e) => {
                                                fileButton.current.click();
                                            }}
                                        />{" "}
                                    </div>
                                    <input
                                        ref={fileButton}
                                        onChange={onPhotoChoose}
                                        type="file"
                                        name=""
                                        className="hidden"
                                        id=""
                                    />
                                </div>
                                <div className="flex w-full items-center justify-center bg-grigio rounded-lg">
                                    <div className="grid grid-cols-2 w-full h-[100%] place-content-center place-items-center gap-2">
                                        {additionalImages.map(
                                            (image, index) => (
                                                <div
                                                    key={index}
                                                    className="flex flex-col w-full h-[100%] items-center relative"
                                                >
                                                    {image ? (
                                                        <>
                                                            <img
                                                                src={image}
                                                                alt={`Immagine facoltativa ${
                                                                    index + 1
                                                                }`}
                                                                className="w-full h-[100%] object-cover rounded"
                                                            />
                                                            <button
                                                                onClick={() =>
                                                                    removeAdditionalPhoto(
                                                                        index
                                                                    )
                                                                }
                                                                className="absolute top-2 right-2 bg-red-600 text-white rounded-full p-1"
                                                            >
                                                                <IoIosClose className="w-5 h-5" />
                                                            </button>
                                                        </>
                                                    ) : (
                                                        <div className="w-full h-[100%]">
                                                            <input
                                                                type="file"
                                                                onChange={(e) =>
                                                                    onAdditionalPhotoChoose(
                                                                        e,
                                                                        index
                                                                    )
                                                                }
                                                                className="hidden"
                                                                id={`additionalImage${index}`}
                                                            />
                                                            <label
                                                                htmlFor={`additionalImage${index}`}
                                                            >
                                                                <FileUploadBtn />
                                                            </label>
                                                        </div>
                                                    )}
                                                </div>
                                            )
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div
                                className={`${
                                    next === 2
                                        ? "block custom-scrollbar"
                                        : "hidden"
                                } flex flex-col items-center h-full w-full overflow-y-auto`}
                            >
                                <div className="w-1/2 my-5 px-2 justify-center flex">
                                    <CategorieDropdownRegalo
                                        setMicro={setCategoria}
                                        micro={categoria}
                                        isOpen={dropdownIsOpen === 3}
                                        setIsOpen={handleSetIsOpen}
                                        opennth={3}
                                    />
                                </div>
                                <div className=" w-1/2 px-2 flex flex-col ">
                                    <label htmlFor="titolo" className="text-sm">
                                        Titolo
                                    </label>
                                    <input
                                        type="text"
                                        id="titolo"
                                        maxLength={50}
                                        value={titolo}
                                        className="appearance-none w-full bg-background border-0 rounded-lg p-2 focus:ring-verde"
                                        onChange={(e) => {
                                            handleTitoloChange(e);
                                            setTitolo(e.currentTarget.value);
                                        }}
                                    />
                                    <label
                                        className="place-self-end text-sm"
                                        htmlFor="titolo"
                                    >
                                        {charCount}/50
                                    </label>
                                </div>
                                <div className=" w-1/2 px-2 flex flex-col ">
                                    <label htmlFor="descr" className="text-sm">
                                        Descrizione
                                    </label>
                                    <textarea
                                        type="text"
                                        id="descr"
                                        value={descrizione}
                                        maxLength={300}
                                        className="appearance-none h-32 w-full bg-background border-0 rounded-lg p-2 focus:ring-verde"
                                        onChange={(e) => {
                                            handleDescChange(e);
                                            setDescrizione(
                                                e.currentTarget.value
                                            );
                                        }}
                                    />
                                    <label
                                        className="place-self-end text-sm"
                                        htmlFor="descr"
                                    >
                                        {charDCount}/300
                                    </label>
                                </div>
                                <div className="flex  px-2 w-1/2 justify-between space-x-5">
                                    <div className="w-full">
                                        <label
                                            htmlFor="descr"
                                            className="text-sm"
                                        >
                                            Disponibilità
                                        </label>
                                        <div className="flex w-full justify-center">
                                            <StaggeredDropDownRegalo
                                                isOpen={dropdownIsOpen === 4}
                                                setIsOpen={handleSetIsOpen}
                                                opennth={4}
                                                setChange={setDisponibilita}
                                                titolo="Disponibilità"
                                                initialValue={disponibilita}
                                                opzioni={[
                                                    {
                                                        text: "Infrasettimanale di giorno",
                                                    },
                                                    {
                                                        text: "Infrasettimanale di sera",
                                                    },
                                                    { text: "Fine settimana" },
                                                    { text: "Flessibile" },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                    <div className="w-full">
                                        <label
                                            htmlFor="descr"
                                            className="text-sm"
                                        >
                                            Condizione
                                        </label>
                                        <div className="flex w-full justify-center">
                                            <StaggeredDropDownRegalo
                                                isOpen={dropdownIsOpen === 2}
                                                setIsOpen={handleSetIsOpen}
                                                setChange={
                                                    setSelectedCondizioni
                                                }
                                                opennth={2}
                                                initialValue={selectedCondizioni}
                                                titolo="Condizione"
                                                opzioni={[
                                                    {
                                                        text: "Come nuovo",
                                                    },
                                                    {
                                                        text: "Buone",
                                                    },
                                                    {
                                                        text: "Accettabili",
                                                    },
                                                    { text: "Da aggiustare" },
                                                ]}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div
                            className={`${
                                next >= 0 ? "flex" : "hidden"
                            } items-center justify-end gap-3 mt-4 rounded-b-lg w-full p-2 pb-4`}
                        >
                            {next === 1 && (
                                <BottoneRosso
                                    text={"Avanti"}
                                    onclick={() => setNext(2)}
                                />
                            )}
                            {next === 2 &&
                                (loading ? (
                                    <div className="flex items-center justify-center gap-2">
                                        <p>Caricamento...</p>
                                    </div>
                                ) : (
                                    <>
                                        <BottoneVerde2
                                            text={"Indietro"}
                                            onclick={() => setNext(next - 1)}
                                        />
                                        <BottoneRosso
                                            text={"Invia"}
                                            onclick={handleSubmit}
                                        />
                                    </>
                                ))}
                        </div>
                    </motion.div>
                </motion.div>
            )}
        </AnimatePresence>
    );
};

export default ModRegalo;
