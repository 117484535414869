import { create } from "zustand";
import { persist } from "zustand/middleware";

const useUserStore = create(
    persist(
        (set) => ({
            user: null,
            isLogged: false,
            setUser: (value) => set({ user: value }),
            setIsLogged: (value) => set({ isLogged: value }),
            cambiaLocalita: async (value) => {
                // async al backend
            }
})));

export default useUserStore;