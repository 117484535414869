import { doc, increment, runTransaction, updateDoc } from "firebase/firestore";
import { auth, db } from "../firebase";
import { getChatFolder } from "./ChatUtils";
import { postNotification } from "./NotificationsUtils";
import globals from "./Globals";

/**
 * 
 * @param {string} receiver 
 * @param {string} itemId 
 * @param {import("./ChatUtils").Chat | null} chat
 * @returns {Promise<boolean>} 
 */

export async function assignPresent(receiver, itemId, userCompleteName, chat=null) {
    // Esegui una transazione per garantire atomicità e gestire le condizioni di concorrenza
    try {
        await runTransaction(db, async (transaction) => {
            const regaloRef = doc(db, "regali", itemId);
            const chatRef = doc(db, "new_chats", getChatFolder(receiver, auth.currentUser.uid, itemId));
            const userRef = doc(db, "users", auth.currentUser.uid);
            const receiverRef = doc(db, "users", receiver);

            // Controlla se il regalo è già stato assegnato
            const regaloDoc = await transaction.get(regaloRef);
            if (!regaloDoc.exists()) {
                throw new Error("Il regalo non esiste");
            }
            if (regaloDoc.data().assegnato) {
                throw new Error("Il regalo è già stato assegnato");
            }

            const prenotati = regaloDoc.data().prenotati || [];

            // Aggiorna il regalo per assegnarlo al ricevitore
            transaction.update(regaloRef, {
                assegnato: receiver,
            });

            // Aggiorna la chat per indicare che la prenotazione è stata accettata
            transaction.update(chatRef, {
                bookingAccepted: true
            });

            // Incrementa i punti dell'utente corrente
            transaction.update(userRef, {
                punti: increment(100),
            });

            // Incrementa il numero di adozioni del ricevitore
            transaction.update(receiverRef, {
                adozioni: increment(1),
            });

            // Invia la notifica all'interno della transazione
            
            await postNotification(receiver, {
                title: userCompleteName,
                content: "Ho deciso di assegnarti il mio regalo: " + regaloDoc.data().titolo,
                item: regaloDoc.data().titolo,
                sender: auth.currentUser.uid,
                type: "assegnazione",
                link: "/chat/" + getChatFolder(receiver, auth.currentUser.uid, itemId)
            }, chat);
        });

        console.log("Regalo assegnato con successo e punti aggiornati");
        return true;
    } catch (error) {
        console.error("Errore durante l'assegnazione del regalo: ", error);
        return false;
    }
}

