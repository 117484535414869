import { driver } from "driver.js";
import "driver.js/dist/driver.css";

const homeTutorialContent = [
    { title: 'Questa è la Home!', description: 'Il punto di partenza per esplorare e scoprire tutto quello che Togiftit offre. Diamo un\'occhiata!' },
    { title: 'Punti Gift', description: 'Questi sono I tuoi Punti Gift, la moneta della generosità! Usali per prenotarti agli oggetti che desideri, presto scoprirai il loro potenziale.' },
    { title: 'Notifiche', description: 'Qui trovi le tue notifiche. Tieni d\'occhio aggiornamenti e messaggi importanti!' },
    { title: 'Ricerca', description: 'Hai qualcosa di specifico in mente? Usa la barra di ricerca per trovarlo in un lampo.' },
    { title: 'Filtri', description: 'Usa i filtri per affinare la tua ricerca! Trova esattamente ciò che cerchi in pochi clic.' },
    { title: 'Regalo', description: 'Queste sono le donazioni! Aprine una per scoprire di più e prenotarti subito' },
]

const homeTutorialSteps = [
    { popover: { ...homeTutorialContent[0], side: 'bottom', align: 'start' } },
    { element: '#badgePuntiSidebar', popover: { ...homeTutorialContent[1], side: 'bottom', align: 'start' } },
    { element: '#notificaNavbarDesktop', popover: { ...homeTutorialContent[2], side: 'left', align: 'start' } },
    { element: '#searchNavbarDesktop', popover: { ...homeTutorialContent[3], side: 'bottom', align: 'center' } },
    { element: '#filtriRegalo', popover: { ...homeTutorialContent[4], side: 'bottom', align: 'start' } },
    { element: '.cardRegaloPubblica', popover: { ...homeTutorialContent[5], side: 'right', align: 'start' } },
];

const homeTutorialMobileSteps = [
    { popover: { ...homeTutorialContent[0], side: 'bottom', align: 'start' } },
    { element: '#badgePuntiNavbar', popover: { ...homeTutorialContent[1], side: 'bottom', align: 'end' } },
    { element: '#notificheNavbar', popover: { ...homeTutorialContent[2], side: 'bottom', align: 'end' } },
    { element: '#searchNavbar', popover: { ...homeTutorialContent[3], side: 'bottom', align: 'center' } },
    { element: '#filtriRegalo', popover: { ...homeTutorialContent[4], side: 'bottom', align: 'center' } },
    { element: '.cardRegaloPubblica', popover: { ...homeTutorialContent[5], side: 'bottom', align: 'start' } },
];

export const getHomeTutorial = (forMobile = false) => driver({
    showProgress: false,
    nextBtnText: 'Avanti',
    prevBtnText: '← Indietro',
    doneBtnText: 'Inizia',
    allowClose: true,
    steps: forMobile ? homeTutorialMobileSteps : homeTutorialSteps,
    popoverClass: 'driverjs-theme'
})