import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import BottoneGrigio from "../Bottoni/BottoneGrigio";
import { IoIosClose } from "react-icons/io";
import Icon from "../../../assets/Illustrazioni/_puntigift.svg";
import useDeviceDetection from "../../../utils/useDeviceDetection";
import useFirstLoginStore from '../../../stores/firstLoginStore'
import DragCloseDrawer from "../Drawers/DragCloseDrawer";

const PuntiGuadagnati = ({ onClose }) => {
    const [isOpen, setIsOpen] = useState(true);
    const isCodiceBenvenutoValido = useFirstLoginStore((state) => state.codiceBenvenutoValido);
    const device = useDeviceDetection();

    const handleClose = async (e) => {
        setIsOpen(false);
        onClose(e);
    };

    useEffect(() => {
        if (isCodiceBenvenutoValido !== true) {
            handleClose();
        }
    }, []);

    const welcomeMessage = (
        <div className="w-full flex px-4 flex-col items-center justify-center text-center gap-5">
            <h1 className="text-3xl font-malik text-center text-verdeScuro font-bold px-4">
                Hai guadagnato 100 punti gift!
            </h1>
            <p className="text-xl">Complimenti, grazie al codice benvenuto del tuo gifter amico<span className="font-bold">hai guadagnato 100 punti gift</span></p>
            <div className="w-2/3 flex justify-center">
                <img src={Icon} alt="Punti" />
            </div>
        </div>
    )
    const welcomeButton = (
        <div className="flex justify-center mt-8">
            <div className="w-full md:w-auto">
                {/* TODO: cambiare con Bottone.jsx della PR#14 */}
                <button
                    type="button"
                    onClick={(e) => handleClose(e)}
                    className="py-2 px-4 w-full min-w-32 h-12 text-center 
                    hover:text-verdeScuro text-white border border-verde hover:bg-verdeScuro/30 hover:border-verdeScuro/30 bg-verde focus:ring-4 focus:outline-none focus:ring-verdeScuro/30 
                    font-semibold rounded-2xl text-xl flex items-center justify-center"
                >
                    Prosegui
                </button>
            </div>
        </div>
    )
    const mobileBody = (
        <DragCloseDrawer open={isOpen}>
            <div className="flex flex-col relative h-full max-w-xl bg-white rounded-lg overflow-hidden justify-around">
                {welcomeMessage}
                {welcomeButton}
            </div>
        </DragCloseDrawer>
    )
    const desktopBody = (
        <motion.div
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            // onClick={() => handleClose(false)}
            className="bg-slate-900/20 text-xs backdrop-blur p-8 fixed inset-0 z-50 grid place-items-center  "
        >
            <motion.div
                initial={{ scale: 0, rotate: "12.5deg" }}
                animate={{ scale: 1, rotate: "0deg" }}
                exit={{ scale: 0, rotate: "0deg" }}
                onClick={(e) => e.stopPropagation()}
                className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden"
            >
                <div className="relative py-4 pb-8 px-4 max-w-xl bg-white rounded-lg overflow-hidden">
                    {welcomeMessage}
                    {welcomeButton}
                </div>
            </motion.div>
        </motion.div>
    )
    const body = device === "Mobile" ? mobileBody : desktopBody

    return (
        <AnimatePresence>
            {isOpen  && body}
        </AnimatePresence>
    );
};

export default PuntiGuadagnati;
