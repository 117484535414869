import React, { useState } from 'react'

import { FiEye, FiEyeOff } from 'react-icons/fi'

const TextInputField = ({ inputStyle, containerStyle, value, setValue, password, placeholder, marginTop = 0 }) => {
    const [passwordType, setPasswordType] = useState(password ? 'password' : 'text')

    return (
        <div className={`relative ${containerStyle || 'w-full'} ${marginTop && `mt-${marginTop}`}`}>
            <input type={passwordType}
                placeholder={placeholder}
                className={`${inputStyle} ${password && 'pr-8'}`}
                value={value}
                onChange={e => setValue(e.target.value)} />
            {password && <button className='absolute top-1/2 -translate-y-1/2 right-2 text-verdeScuro'
                onClick={() => setPasswordType(passwordType === 'password' ? 'text' : 'password')}>
                {
                    passwordType === 'password' ?
                        <FiEye /> : <FiEyeOff />
                }
            </button>}
        </div>
    )
}

export default TextInputField