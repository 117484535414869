import { useState, useEffect, useContext } from "react";
import Bottone from "../../Components/Atoms/Bottoni/Bottone";
import ButtonTimer from "../../Components/Atoms/Bottoni/ButtonTimer";
import TextInputField from "../../Components/Atoms/Inputs/TextInputField";
import MailAmicoImage from "../../assets/Illustrazioni/Mail-amico.svg";
import ImageRegistrati from "../../assets/Illustrazioni/Registrati.svg";
import axios from "axios";
import LoadingBtn from "../../Components/Atoms/Bottoni/LoadingBtn";

const inputClassName =
    "text-xl text-verde font-semibold w-full bg-transparent border-transparent focus:border-transparent focus:ring-0 rounded-md";
const containerClassName = "flex flex-col w-full h-full";
const titleClassName = "text-2xl font-semibold";

export const RecuperoStepEmail = ({ nextStep, setDefaultButtonsHidden }) => {
    const [email, setEmail] = useState("");
    const [loading, setLoading] = useState(false);
    const [res, setres] = useState("");

    useEffect(() => {
        setDefaultButtonsHidden(true);
    }, []);

    const isValid = () => {
        if (!email) return false;
        if (!email.includes("@")) return false;
        if (!email.includes(".")) return false;
        return true;
    };

    const handleOnClose = async () => {
        if (!isValid()) return;

        const headers = {
            "Access-Control-Allow-Origin": "*",
        };
        await axios
            .post(
                process.env.REACT_APP_API_URL + "/mail/resetpassword",
                {
                    email: email,
                },
                { headers }
            )
            .then((response) => {
                console.log(response.data);
                

                
                setres(response.data.message);

                if (response.data.status === "ok") {
                    nextStep();
                }
            });

    };

    return (
        <div className={containerClassName}>
            <div className="flex flex-col flex-1 space-y-8 md:space-y-10">
                <h2 className={titleClassName}>Non ricordi la password?</h2>
                <TextInputField
                    placeholder={"Inserisci la tua mail"}
                    inputStyle={inputClassName}
                    marginTop={6}
                    value={email}
                    setValue={(e) => setEmail(e)}
                />
                <div className="flex flex-col pr-6 text-lg">
                    <p className="font-semibold">
                        Tranquillo, succede a tutti ! ;&#41;
                    </p>
                    <p className="">
                        Inserisci la tua mail e riceverai un link per
                        reimpostare la password.
                    </p>
                </div>
                <img
                    src={ImageRegistrati}
                    alt="Registrati"
                    className="w-full md:hidden"
                />
            </div>
            <div className="w-full flex justify-end">
                <LoadingBtn
                    textStyle="text-white font-semibold"
                    fullWidth={true}
                    bgColor="bg-verde"
                    onClick={handleOnClose}
                    disabled={!isValid()}
                >
                    Prosegui
                </LoadingBtn>
            </div>
        </div>
    );
};

export const ConfermaEmail = () => {
 
    return (
        <div className={containerClassName}>
            <div className="flex flex-col flex-1 space-y-6">
                <h2 className={titleClassName}>Mail inviata correttamente</h2>
                <img
                    src={MailAmicoImage}
                    alt="Registrati"
                    className="w-2/3 md:w-1/4 place-self-center"
                />
                <div className="flex flex-col pr-8 text-lg">
                    <p className="font-semibold">
                        Controlla la tua casella di posta,
                    </p>
                    <p className="">
                        Troverai una mail con un link per reimpostare la tua
                        password
                    </p>
                </div>
                <div className="flex flex-col space-y-6 md:space-y-0 pt-8">
                    <p className="font-semibold place-self-center text-lg">
                        Non hai ricevuto la mail?
                    </p>
                    <div className=" hidden justify-center">
                        <ButtonTimer
                            onClick={() => alert("Codice reinviato")}
                            delay={60000}
                            waitingText="Reinvia mail tra"
                            customStyle="w-1/2 flex justify-center underline"
                            bgColor={"transparent"}
                            textStyle={"text-verdeScuro"}
                            initialState={true}
                        >
                            Reinvia mail
                        </ButtonTimer>
                    </div>
                </div>
            </div>
        </div>
    );
};
