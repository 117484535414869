import React, { useEffect, useState } from "react";
import { FiMenu, FiArrowRight, FiX } from "react-icons/fi";
import { FaInstagram, FaFacebookF, FaTiktok } from "react-icons/fa";
import { AnimatePresence, motion } from "framer-motion";
import useMeasure from "react-use-measure";
import Logo from "../../../assets/Logos/Logo";
import Bottone from "../../Atoms/Bottoni/Bottone";

const NavLanding = ({ scrolled, onclick, logged }) => {
    return (
        <nav
            className={`fixed top-0 z-50 w-full h-[5rem] md:p-4 p-5 
      transition-all duration-300 ease-out lg:pl-12 lg:pr-0 shadow-md
      ${scrolled ? "bg-verdeScuro text-white" : "bg-white text-verdeScuro "}`}
        >
            <div className="mx-auto flex max-w-7xl items-center justify-between">
                <div
                    className="w-24  cursor-pointer"
                    onClick={() => {
                        window.location.href = "/";
                    }}
                >
                    <Logo />
                </div>
                <div className="hidden gap-6 lg:flex">
                    <Links onclick={onclick} />
                    <CTAs scrolled={scrolled} />
                    <Bottone
                        onClick={() =>
                            (window.location.href = logged ? "/" : "/webapp")
                        }
                    >
                        Vai alla WebApp
                    </Bottone>
                </div>
                <div className="flex items-center gap-3 md:hidden">
                    {" "}
                    <Bottone
                    height={10}
                        onClick={() =>
                            (window.location.href = logged ? "/" : "/webapp")
                        }
                    >
                        Vai alla WebApp
                    </Bottone>
                    <MobileMenu onclick={onclick} />
                </div>
            </div>
        </nav>
    );
};

const Links = ({ onclick }) => {
    return (
        <div className="flex items-center gap-x-6">
            {LINKS.map((l) => (
                <NavLink key={l.text} href={l.href} FlyoutContent={l.component}>
                    {l.text}
                </NavLink>
            ))}
            <button onClick={onclick}>Contatti</button>
        </div>
    );
};

const NavLink = ({ children, href }) => {
    return (
        <div className="relative h-fit w-fit">
            <a href={href} className="relative">
                {children}
            </a>
        </div>
    );
};

const CTAs = ({ scrolled }) => {
    return (
        <div
            className={`flex items-center gap-x-3 ${
                scrolled ? "text-white" : "text-verdeScuro"
            }`}
        >
            <button
                className="cursor-pointer"
                onClick={() =>
                    window.open(
                        "https://www.facebook.com/profile.php?id=100091961577076"
                    )
                }
            >
                <FaFacebookF className="w-5 h-5" />
            </button>
            <button
                className="cursor-pointer"
                onClick={() => window.open("https://instagram.com/togiftit/")}
            >
                <FaInstagram className="w-5 h-5" />
            </button>
            <button
                className="cursor-pointer"
                onClick={() => window.open("https://tiktok.com/@togiftit")}
            >
                <FaTiktok className="w-5 h-5" />
            </button>
        </div>
    );
};

const MobileMenuLink = ({ href, text }) => {
    const [ref, { height }] = useMeasure();
    const [open, setOpen] = useState(false);

    return (
        <div className="relative">
            <a
                href={href}
                className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl font-semibold"
            >
                <span>{text}</span>
                <FiArrowRight />
            </a>
        </div>
    );
};

const MobileMenu = ({ onclick }) => {
    const [open, setOpen] = useState(false);
    return (
        <div className="block lg:hidden">
            <button onClick={() => setOpen(true)} className="block text-3xl">
                <FiMenu />
            </button>
            <AnimatePresence>
                {open && (
                    <motion.nav
                        initial={{ x: "100vw" }}
                        animate={{ x: 0 }}
                        exit={{ x: "100vw" }}
                        transition={{ duration: 0.15, ease: "easeOut" }}
                        className="fixed left-0 top-0 flex h-screen w-full flex-col bg-white px-6"
                    >
                        <div className="flex items-center justify-between py-6">
                            <div className="w-[50%]">
                                <Logo />
                            </div>
                            <button onClick={() => setOpen(false)}>
                                <FiX className="text-3xl text-verdeScuro" />
                            </button>
                        </div>
                        <div className="text-verdeScuro overflow-y-scroll py-6 pb-0">
                            {LINKS.map((l) => (
                                <MobileMenuLink text={l.text} href={l.href}>
                                    {l.text}
                                </MobileMenuLink>
                            ))}
                        </div>
                        <button
                            onClick={() => {
                                setOpen(false);
                                onclick();
                            }}
                            className="flex w-full cursor-pointer items-center justify-between border-b border-neutral-300 py-6 text-start text-2xl text-verdeScuro font-semibold"
                        >
                            Contatti
                            <FiArrowRight />
                        </button>
                        <div className="flex justify-end  py-6">
                            <CTAs />
                        </div>
                    </motion.nav>
                )}
            </AnimatePresence>
        </div>
    );
};

export default NavLanding;

const LINKS = [
    {
        text: "Home",
        href: "/landing",
    },
    {
        text: "Chi siamo?",
        href: "/chisiamo",
    },
    {
        text: "Come funziona?",
        href: "/faq",
    },
    {
        text: "Local Heroes",
        href: "/localheroes",
    },
];
