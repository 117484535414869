import React from "react";

function Oggetti() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 541.74 145.94">
      <g data-name="Livello_1">
        <path
          d="M386.27 126.31c.5 1.97 2.77 3.01 4.8 2.96 2.03-.05 3.95-.89 5.92-1.38.63-.16 1.3-.28 1.92-.08.57.18 1.02.62 1.51.96 2.34 1.6 5.46 1.01 8.25.55 6.44-1.06 13.01-1.06 19.53-1.07 13.27 0 26.75.03 39.51 3.68.88.25 1.84.52 2.68.16.7-.29 1.16-.96 1.78-1.4 1.57-1.12 3.71-.62 5.6-.28 1.9.34 4.27.24 5.2-1.45.58-1.05.35-2.38-.17-3.46-1.27-2.64-3.98-4.26-6.65-5.48-11.6-5.3-24.74-5.85-37.49-6.31l-10.74-.39c-5.32-.19-10.64-.38-15.97-.42-5.54-.04-11.34.65-16.28 3.34-3.1 1.69-10.49 5.83-9.43 10.06zM220.65 132.53c-.08.03-.17.05-.25.08-1.8.57-4.9 1.55-4.66 3.95.43 4.19 9.98 3.68 12.72 3.59 5.65-.18 11.28-1.25 16.6-3.17 1.6-.58 3.47-1.72 3.23-3.4-.22-1.53-2.03-2.19-3.54-2.47a50.46 50.46 0 00-24.1 1.42zM306.07 120.92c-3.93.07-7.84.74-11.29 2.57-3.99 2.12-2.08 5.29.92 7.21 3.54 2.27 6.51 3.15 10.7 3.73 9.88 1.36 19.89 1.74 29.85 1.12 1.77-.11 3.57-.26 5.2-.93 1.64-.67 3.11-1.96 3.58-3.67.69-2.52-1.05-5.2-3.35-6.43-4.52-2.41-10.92-1.66-15.85-1.84-5.25-.19-10.44-1.26-15.68-1.63-1.35-.1-2.71-.14-4.08-.12z"
          className="oggetti-cls-6"
        ></path>
        <path
          d="M292.68 49.01H340.77V56.41H292.68z"
          className="oggetti-cls-19"
          transform="rotate(-16.26 316.76 52.715)"
        ></path>
        <path
          d="M341 49.61l-46.4 13.54-2.14-7.34 46.4-13.54 2.14 7.34zm-46.24 13.23l45.92-13.4-2-6.86-45.92 13.4 2 6.86z"
          className="oggetti-cls-9"
        ></path>
        <path
          d="M293.13 53.77H341.22V54.720000000000006H293.13z"
          className="oggetti-cls-19"
          transform="rotate(-16.26 317.22 54.245)"
        ></path>
        <path
          d="M340.54 48.05l-46.4 13.54-.34-1.15 46.4-13.54.34 1.15zm-46.23 13.23l45.92-13.4-.2-.67-45.92 13.4.2.67z"
          className="oggetti-cls-9"
        ></path>
        <path
          d="M292.58 51.88H340.66999999999996V52.830000000000005H292.58z"
          className="oggetti-cls-19"
          transform="rotate(-16.26 316.67 52.345)"
        ></path>
        <path
          d="M339.99 46.16l-46.4 13.54-.34-1.15 46.4-13.54.34 1.15zm-46.23 13.23l45.92-13.4-.2-.67-45.92 13.4.2.67z"
          className="oggetti-cls-9"
        ></path>
        <path
          d="M292.05 50.07H340.14V51.02H292.05z"
          className="oggetti-cls-19"
          transform="rotate(-16.26 316.139 50.555)"
        ></path>
        <path
          d="M339.46 44.35l-46.4 13.54-.34-1.15 46.4-13.54.34 1.15zm-46.23 13.23l45.92-13.4-.2-.67-45.92 13.4.2.67z"
          className="oggetti-cls-9"
        ></path>
        <ellipse
          cx="129.11"
          cy="138.2"
          className="oggetti-cls-6"
          rx="6.88"
          ry="33.99"
          transform="rotate(-83.9 129.117 138.2)"
        ></ellipse>
        <path
          d="M66.24 121.63c.51-.38.98-.77 1.69-1.07 1.28-.54 3.27-.76 4.64-1.1 7.71-1.89 15.45 1.36 23.09-2.56 4.72-2.42 8.34-6.82 13.36-8.52 4.71-1.59 14.11-4.14 9.76-11.4-3.63-6.07-13.52-2.54-18.34-.48-3.43 1.47-6.58 3.45-9.96 4.9-2.69 1.15-5.62 1.73-8.42 2.76-1.52.56-1.27.99-2.17 1.6-1.44.98-5.28 3.91-6.93 4.21-.88.16-1.72.08-2.58.1-1.8.03-3.7.47-5.56.91-2.15.5-4.34 1.02-6.33 1.97-2.99 1.43-5.29 3.83-5.8 6.13-.51 2.3.84 4.41 3.42 5.23.64.2 1.37.33 2.16.28 1.05-.08 2.12-.47 3.15-.86l3.27-1.23c.66-.25 1.12-.56 1.55-.88z"
          className="oggetti-cls-6"
        ></path>
        <path
          d="M55.2 62.75s-26.65 20.99-4.59 30.9c22.07 9.91 14.33-30.42 4.59-30.9zM98.98 58.2s30.63 14.59 11.29 29.13C90.94 101.87 89.58 60.82 98.98 58.2z"
          className="oggetti-cls-10"
        ></path>
        <path
          d="M56.46 59.76s-23.25 57.56 25.41 51.64c49.72-5.55 15.16-56.17 15.16-56.17l-40.57 4.53z"
          className="oggetti-cls-15"
        ></path>
        <path
          d="M68.41 77.02s-8.03 23.54 12.22 21.51c20.25-2.02 6.75-23.63 6.75-23.63-4.87-6.43-15.84-6.16-18.97 2.12z"
          className="oggetti-cls-10"
        ></path>
        <path
          d="M62.89 17.27s-10.7-12.26-19.32-3.92c-8.62 8.34-1.05 23.58 9.79 19.65 10.85-3.94 9.53-15.73 9.53-15.73z"
          className="oggetti-cls-20"
        ></path>
        <path
          d="M58.4 18.65s-5.57-6.38-10.06-2.04-.55 12.28 5.1 10.23c5.65-2.05 4.96-8.19 4.96-8.19z"
          className="oggetti-cls-10"
        ></path>
        <path
          d="M80.34 15.32S88.07 1 98.32 7.23c10.25 6.24 6.23 22.77-5.21 21.32-11.45-1.45-12.77-13.24-12.77-13.24z"
          className="oggetti-cls-20"
        ></path>
        <path
          d="M85.03 15.68s4.03-7.46 9.36-4.21c5.33 3.25 3.25 11.85-2.71 11.1s-6.65-6.89-6.65-6.89z"
          className="oggetti-cls-10"
        ></path>
        <ellipse
          cx="74.26"
          cy="39.94"
          className="oggetti-cls-15"
          rx="29.52"
          ry="26.18"
          transform="rotate(-6.38 74.176 39.95)"
        ></ellipse>
        <path
          d="M62.93 47.92c-.71-6.38.47-16.99 10.2-18.08 9.73-1.09 13.22 9 13.94 15.38 1.27 11.37-22.86 14.07-24.13 2.7z"
          className="oggetti-cls-10"
        ></path>
        <path
          d="M67.71 31.92s2.96 3.2 5.99 2.98c3.03-.22 5.18-4.23 5.18-4.23-1.26-2.13-10.84-.98-11.16 1.25z"
          className="oggetti-cls-3"
        ></path>
        <path
          d="M73.87 33.19H74.54V45.83H73.87z"
          className="oggetti-cls-3"
          transform="rotate(-6.38 74.2 39.502)"
        ></path>
        <path
          d="M65.11 31.14c-.64.07-1.22-.39-1.29-1.03l-.07-.65a1.168 1.168 0 012.32-.26l.07.65c.07.64-.39 1.22-1.03 1.29zM81.23 29.34c-.64.07-1.22-.39-1.29-1.03l-.07-.65a1.168 1.168 0 012.32-.26l.07.65c.07.64-.39 1.22-1.03 1.29zM73.41 46.14c-1.65-.08-3.31-.54-4.96-1.38l.31-.6c4.22 2.16 8.38 1.7 12.34-1.39l.41.53c-2.59 2.01-5.33 2.96-8.1 2.83z"
          className="oggetti-cls-3"
        ></path>
        <path
          d="M64.63 98.88c3.82 6.37 2.85 13.47-2.18 15.88-5.03 2.4-12.21-.81-16.03-7.18-3.82-6.37-2.85-13.47 2.18-15.88 5.03-2.4 12.21.81 16.03 7.18zM97.56 95.2c-2.32 7.05.2 13.77 5.63 15 5.43 1.23 11.73-3.48 14.05-10.54 2.32-7.05-.2-13.77-5.63-15-5.43-1.23-11.73 3.48-14.05 10.54z"
          className="oggetti-cls-10"
        ></path>
        <path
          d="M99.26 58.18l-1.29-3.24c-13.01 8-29.66 9.74-43.08 5.22l-.1 2.98c15.63 5.83 29.93 4.18 44.46-4.97z"
          className="oggetti-cls-26"
        ></path>
        <path
          d="M58.16 112.91c-.82-.04-1.65-.25-2.47-.63-2.24-1.04-4.28-3.37-5.08-5.78-.54-1.61-.7-3-.49-4.13.21-1.1.78-1.95 1.69-2.51 1.41-.87 3.54-1.07 5.69-.52 2.35.59 4.38 2.02 5.43 3.81 1.07 1.83 1.31 4.17.64 6.11-.54 1.56-1.6 2.69-3.07 3.27-.75.3-1.54.43-2.35.39zM55.51 99.7c-1.3-.06-2.48.18-3.34.72-.75.46-1.21 1.14-1.38 2.06-.19 1.02-.04 2.29.46 3.8.75 2.25 2.65 4.41 4.73 5.38 1.46.68 2.94.76 4.28.23 1.61-.64 2.35-1.91 2.68-2.87.6-1.73.37-3.91-.59-5.55-.96-1.64-2.83-2.95-5.01-3.5-.62-.16-1.23-.25-1.82-.27zM104.71 107.71c-.2 0-.41-.03-.61-.06-1.56-.24-2.84-1.11-3.71-2.52-1.08-1.75-1.36-4.08-.72-6.1.63-1.98 2.29-3.82 4.45-4.92 1.97-1 4.09-1.28 5.66-.75 1.01.35 1.76 1.04 2.2 2.07.46 1.05.61 2.45.44 4.14-.25 2.53-1.73 5.25-3.68 6.76-1.24.96-2.62 1.43-4.03 1.36zm3.59-13.93c-1.19-.06-2.56.27-3.88.94-2 1.02-3.54 2.71-4.12 4.52-.57 1.81-.32 3.98.65 5.54.53.86 1.53 1.94 3.24 2.21 1.43.22 2.86-.18 4.13-1.17 1.81-1.41 3.19-3.94 3.42-6.3.16-1.58.02-2.86-.39-3.8-.38-.86-.97-1.42-1.8-1.71-.39-.13-.81-.21-1.26-.23z"
          className="oggetti-cls-20"
        ></path>
        <g>
          <circle
            cx="77.85"
            cy="74.77"
            r="7.38"
            className="oggetti-cls-5"
          ></circle>
          <path
            d="M77.53 61.39c.16.01.33.03.47.1.52.27.37 1.5.37 1.98 0 .92-.16 1.84-.44 2.72-.05.14-.1.29-.21.38-.09.07-.2.1-.3.12-.55.13-1.11.22-1.67.26-.08 0-.16.01-.23-.02-.09-.04-.14-.12-.19-.21a9.65 9.65 0 01-.91-2.08c-.27-.87-1.3-2.16-.24-2.78.98-.58 2.2-.55 3.36-.47z"
            className="oggetti-cls-5"
          ></path>
        </g>
        <g>
          <path
            d="M99 126.12H162.96V133.43H99z"
            className="oggetti-cls-3"
            transform="rotate(12.2 130.986 129.761)"
          ></path>
          <rect
            width="5.19"
            height="80.73"
            x="136.15"
            y="53.53"
            className="oggetti-cls-3"
            rx="2.35"
            ry="2.35"
            transform="rotate(12.2 138.748 93.915)"
          ></rect>
          <rect
            width="57.26"
            height="12.29"
            x="104.42"
            y="114.05"
            className="oggetti-cls-4"
            rx="5.57"
            ry="5.57"
            transform="rotate(12.2 133.042 120.22)"
          ></rect>
          <rect
            width="47.42"
            height="12.29"
            x="111.94"
            y="102.04"
            className="oggetti-cls-7"
            rx="5.57"
            ry="5.57"
            transform="rotate(12.2 135.638 108.207)"
          ></rect>
          <rect
            width="40.22"
            height="12.29"
            x="118.14"
            y="90.03"
            className="oggetti-cls-13"
            rx="5.57"
            ry="5.57"
            transform="rotate(12.2 138.235 96.194)"
          ></rect>
          <rect
            width="33.92"
            height="12.29"
            x="123.89"
            y="78.02"
            className="oggetti-cls-15"
            rx="5.57"
            ry="5.57"
            transform="rotate(12.2 140.831 84.18)"
          ></rect>
          <rect
            width="26.11"
            height="12.29"
            x="130.39"
            y="66.01"
            className="oggetti-cls-21"
            rx="5.57"
            ry="5.57"
            transform="rotate(12.2 143.428 72.168)"
          ></rect>
          <path
            d="M154.5 73.29c-.15-1.96-1.14-3.17-3.04-3.7l.18-.63c2.16.6 3.34 2.05 3.51 4.28l-.65.05zM155.56 85.96c-.15-1.96-1.14-3.17-3.04-3.7l.18-.63c2.16.6 3.35 2.05 3.52 4.28l-.65.05zM156.05 98.61c-.15-1.96-1.14-3.17-3.04-3.7l.18-.63c2.16.6 3.35 2.05 3.51 4.28l-.65.05zM157.02 111.44c-.15-1.96-1.14-3.17-3.04-3.7l.18-.63c2.16.6 3.35 2.05 3.52 4.28l-.65.05zM159.16 124.61c-.15-1.96-1.14-3.17-3.04-3.7l.18-.63c2.16.6 3.35 2.05 3.51 4.28l-.65.05z"
            className="oggetti-cls-19"
          ></path>
        </g>
        <g>
          <path
            d="M126.82 19.42c6.52 1.07 10.58-3.26 12.62-6.78 2.04-3.52 3.42-7.87 4-11.9-1.3 5.66-1.62 8.08-1.75 12.32-.13 4.25 3.02 9.86 7.98 11.92-3.34-1.22-7.03-.25-9.19 1.7-2.16 1.95-3.06 4.68-3.63 7.4-.56 2.72-.85 5.53-2.02 8.05-.07-4.17 2.28-9.11 1.63-13.32-.65-4.21-4.53-9.04-9.64-9.39"
            className="oggetti-cls-12"
          ></path>
          <path
            d="M126.62 20.15c11.03 1.63 16.19-10.18 17.55-19.2.14-.93-1.23-1.36-1.45-.4-.58 2.54-1.13 5.09-1.44 7.67-.26 2.16-.54 4.49-.21 6.66.69 4.6 3.96 8.91 8.23 10.76l.58-1.37c-4.07-1.38-8.69-.09-11.26 3.4-3.03 4.12-2.42 9.56-4.42 14.09l1.4.38c0-5.24 3.03-10.41 1.03-15.6-1.59-4.15-5.29-7.45-9.8-7.85-.96-.09-.96 1.42 0 1.5 4.22.37 7.72 3.94 8.74 7.95 1.18 4.63-1.47 9.36-1.47 14.01 0 .69 1.08 1.09 1.4.38 1.75-3.96 1.51-8.5 3.4-12.4s6.35-5.84 10.59-4.4c.79.27 1.36-1.03.58-1.37-4-1.73-7.21-5.85-7.58-10.26s.74-8.94 1.7-13.14l-1.45-.4c-1.2 7.98-5.61 19.65-15.7 18.16-.94-.14-1.35 1.31-.4 1.45z"
            className="oggetti-cls-18"
          ></path>
        </g>
        <g>
          <path
            d="M.77 74.02c4.42-1.53 5.5-5.73 5.59-8.72s-.52-6.29-1.51-9.11c1.1 4.13 1.71 5.81 3.06 8.61s5.21 5.39 8.99 5.03c-2.5.35-4.47 2.24-5.16 4.24-.69 2-.33 4.09.23 6.05s1.33 3.89 1.45 5.93c-1.45-2.69-1.65-6.71-3.47-9.22S4.08 72.49.77 74.02"
            className="oggetti-cls-12"
          ></path>
          <path
            d="M.97 74.74c7.69-2.84 6.77-12.45 4.6-18.75-.31-.9-1.7-.54-1.45.4.85 3.17 1.75 6.71 3.54 9.51 1.94 3.03 5.65 4.92 9.23 4.68l-.2-1.47c-2.98.51-5.42 2.85-5.96 5.85-.69 3.87 1.61 7.33 1.93 11.09l1.4-.38c-1.67-3.27-1.57-7.36-4.22-10.13-2.42-2.52-6.2-3.55-9.45-2.18-.89.37-.12 1.67.76 1.3 3.05-1.29 6.9.35 8.53 3.13s1.69 5.9 3.09 8.64c.33.64 1.46.36 1.4-.38-.26-3.14-1.9-6.01-2.05-9.15s1.83-5.8 4.98-6.34c.83-.14.64-1.53-.2-1.47-3.43.23-6.89-1.71-8.41-4.81-1.3-2.65-2.15-5.44-2.91-8.28l-1.45.4C5.97 61.74 7.37 70.8.57 73.3c-.9.33-.51 1.78.4 1.45z"
            className="oggetti-cls-18"
          ></path>
        </g>
        <g>
          <path
            d="M523.48 64.86c5.03 1.12 8.46-2.2 10.27-4.95 1.81-2.75 3.14-6.19 3.84-9.4-1.35 4.5-1.75 6.43-2.1 9.83s1.77 8.05 5.52 9.9c-2.54-1.11-5.48-.48-7.28 1-1.8 1.48-2.67 3.64-3.27 5.8s-1 4.41-2.06 6.39c.19-3.35 2.33-7.23 2.07-10.64-.26-3.41-3-7.45-6.97-7.93"
            className="oggetti-cls-12"
          ></path>
          <path
            d="M523.28 65.59c8.79 1.76 13.44-7.78 15.03-14.87.21-.94-1.17-1.33-1.45-.4-.61 2.05-1.21 4.12-1.6 6.23-.31 1.7-.68 3.55-.54 5.29.32 3.73 2.56 7.33 5.9 9.06l.58-1.37c-3.25-1.3-7.02-.33-9.2 2.42-2.56 3.22-2.45 7.56-4.26 11.12l1.4.38c.33-4.18 2.94-8.1 1.77-12.37-.93-3.39-3.66-6.39-7.23-6.92-.94-.14-1.35 1.3-.4 1.45 3.36.5 5.9 3.49 6.38 6.8.54 3.73-1.73 7.36-2.02 11.05-.06.74 1.07 1.03 1.4.38 1.54-3.03 1.68-6.54 3.25-9.55 1.66-3.19 5.13-4.66 8.52-3.3.79.32 1.33-.98.58-1.37-3.09-1.61-5.28-5.15-5.19-8.67s1.13-6.93 2.11-10.21l-1.45-.4c-1.37 6.13-5.33 15.4-13.18 13.83-.94-.19-1.35 1.26-.4 1.45z"
            className="oggetti-cls-18"
          ></path>
        </g>
        <g>
          <path
            d="M258.85 111.65c4.85-1.83 5.94-7.46 5.96-11.5s-.72-8.54-1.89-12.41c1.31 5.65 2.03 7.95 3.58 11.83 1.55 3.88 5.87 7.57 10.04 7.3-2.75.34-4.88 2.79-5.59 5.46-.72 2.67-.27 5.52.4 8.2.67 2.69 1.56 5.34 1.74 8.1-1.66-3.72-1.98-9.17-4.05-12.67s-6.58-6.19-10.19-4.31"
            className="oggetti-cls-12"
          ></path>
          <path
            d="M259.05 112.38c9.24-3.71 6.76-17.54 4.6-24.84-.27-.93-1.67-.54-1.45.4.97 4.18 2.03 8.41 3.8 12.34s6 7.52 10.55 7.34l-.2-1.47c-3.87.61-6.13 4.34-6.46 7.99-.45 4.99 2.06 9.6 2.46 14.5l1.4-.38c-1.88-4.37-1.75-9.61-4.64-13.55-2.39-3.26-6.7-5.59-10.63-3.7-.87.42-.11 1.71.76 1.3 3.87-1.86 8.08 1.61 9.66 4.98 1.75 3.71 1.93 7.96 3.55 11.73.29.68 1.46.34 1.4-.38-.34-4.17-2.16-8.05-2.46-12.21-.27-3.73 1.16-8.18 5.36-8.84.84-.13.64-1.51-.2-1.47-4.61.18-8.14-3.72-9.69-7.69-1.38-3.54-2.35-7.19-3.21-10.89l-1.45.4c1.33 4.49 2.19 9.35 1.77 14.05-.32 3.6-1.71 7.5-5.32 8.95-.88.36-.5 1.81.4 1.45z"
            className="oggetti-cls-18"
          ></path>
        </g>
        <g>
          <path
            d="M356.1 32.59c5.61-.62 8.29-6.26 9.41-10.52 1.12-4.26 1.56-9.21 1.38-13.61-.15 6.32-.02 8.94.57 13.45s4.14 9.59 8.61 10.43c-2.99-.39-5.9 1.62-7.39 4.25-1.48 2.63-1.78 5.75-1.81 8.76-.02 3.01.2 6.06-.36 9.02-.74-4.37.4-10.21-.83-14.47-1.24-4.26-5.26-8.32-9.58-7.31"
            className="oggetti-cls-12"
          ></path>
          <path
            d="M356.1 33.34c10.71-1.39 11.84-16.63 11.54-24.88-.03-.96-1.48-.97-1.5 0-.11 4.78-.24 9.77.73 14.48s4.32 9.13 8.99 10.12l.2-1.47c-4.36-.43-7.78 3.07-9.04 6.95-1.65 5.06-.33 10.46-1.23 15.63h1.45c-.81-5.16.78-10.71-1.26-15.67-1.64-4-5.5-7.56-10.08-6.64-.95.19-.55 1.64.4 1.45 4.54-.91 8.06 3.75 8.89 7.7.93 4.45-.09 9.08.61 13.56.11.72 1.32.71 1.45 0 .78-4.49-.08-9.07.72-13.55.71-4.02 3.47-8.39 8.11-7.93.84.08 1.02-1.3.2-1.47-4.63-.98-7.56-5.79-8.13-10.22s-.6-8.61-.5-12.94h-1.5c.18 5.06-.22 10.38-1.9 15.2-1.32 3.78-3.86 7.62-8.14 8.18-.94.12-.96 1.62 0 1.5z"
            className="oggetti-cls-18"
          ></path>
        </g>
        <g>
          <path
            d="M238.47 11.81c-.04-8.27-4.53 11.22-10.92 21.54-6.49 10.47-3.68 17.68-3.62 24.61.03 3.6-.66 9.29-1.46 13.03 11.71-16.96 7.52-40.02 16-59.18zM185.99 69.33c1.72 6.71 14.62 10.38 19.77 13.34.93.53 1.49.9 1.75 1.15 3.59 1.11 6.76 2.76 8.59 6.42 0-3.33-2.22-5.55-4.44-6.66-18.59-14.68-32.01-32.81-33.42-55.15-.92 3.64-1.19 14.5.15 21.81 1.69 9.19 5.88 12.38 7.59 19.1z"
            className="oggetti-cls-25"
          ></path>
          <path
            d="M186.11 81.35c-6.52-3.73-8.36-12.14-11.4-19.34-1.74-1.31-.01 1.39 1.49 8.65 1.52 7.36 8.37 14.72 16.18 17.05 7.81 2.33 11.89 3.74 19.72 6.61 7.42 2.72 7.94 8.54 9.33 7.14-6.87-12.71-22.76-14.31-35.31-20.11z"
            className="oggetti-cls-25"
          ></path>
          <path
            d="M254.97 50.31C242.83 60.71 235 75.78 223.88 86.9c.9-3.62 1.82-7.22 3.32-10.24-1.99 1.16 9.61-17.98 9.55-26.04-.06-8.7 2.7-9.65 3.25-19.23.55-9.58-1.61-10.05-1.53-19.24v-.35c-8.48 19.17-4.29 42.22-16 59.18-.74 3.45-1.57 5.25-2.03 2.23-.95-6.31 2.6-12.87-2.74-27.04s-4.15-10.45-4.48-24.19C212.92 9.49 201.7-.64 200.52.89c2.64 3.79 4.48 7.82 4.48 11.59 0 4.01-.52 8.03-.49 12.06.03 3.89.25 7.77.66 11.64.73 6.92 1.36 14.31 5.54 20.09 1.37 1.89 2.91 3.69 3.87 5.84 1.68 3.73 1.41 8.05 2.63 11.96.72 2.3 1.98 4.6 1.59 6.98-.58.34-2.23.87-2.77.42-1.99-1.66.09-2.1-10.47-10.03-10.57-7.94-11.65-11.45-13.72-17.56-2.08-6.11-5.64-6.64-8.25-10.96-2.61-4.32-3.05-14.73-4.53-15.7-.3-.2-.58.27-.81 1.2 1.41 22.34 14.82 40.47 33.42 55.15 2.22 1.11 4.44 3.33 4.44 6.66-1.83-3.66-5-5.31-8.59-6.42 1.2 1.14-3.83-.25-9.53-.64-3.27-.22-6.01-4.61-9.7-7.26-4.16-2.99-5-2.42-7.86-6.33-1.83-2.49-3.77-6.08-5.64-7.53-.03-.02-.05-.03-.07-.05 3.04 7.2 4.88 15.62 11.4 19.34 12.55 5.79 28.44 7.4 35.31 20.11.08-.08.15-.16.24-.29 1.59-2.36.63-6.9 1.5-8.33 4.7-7.67 15.51-15.42 20.54-22.77 5.03-7.36 9.21-14.26 11.01-18.51.29-.67.36-1.07.25-1.24z"
            className="oggetti-cls-4"
          ></path>
          <path
            d="M254.97 50.31c-.56-.9-6.14 4.48-11.59 7.78C236.9 62 232.62 71 227.94 75.99c-.35.37-.58.58-.74.67-1.5 3.02-2.42 6.62-3.32 10.24 11.12-11.12 18.95-26.19 31.09-36.59zM200.66 53.39c1.38 4.08 2.14 8.49 4.78 11.29 2.37 2.52 4.74 5.21 6.63 8.12a31.05 31.05 0 012.35 4.27c.2.45 1.42 4.77 1.53 4.79-.44-.07-.87-.14-1.31-.2.54.66 1.44.99 2.28.84.84-.15 1.56-.79 1.83-1.6.12-.38.15-.78.14-1.17-.07-2.52-1.62-4.74-2.23-7.19-.52-2.06-.36-4.22-.65-6.32-.52-3.72-2.43-7.45-4.62-10.45s-3.65-6.48-4.73-10.07c-.91-3.02-.89-5.82-1.14-8.9-.18-2.26-.71-4.44-.83-6.74-.31-5.9.32-11.69.32-17.56 0-3.77-1.84-7.8-4.48-11.59-.12.15-.14.42-.03.82 2.91 11.03-.68 14.15-2.81 23.08-.82 3.45-2.37 12.39-2.03 18.11.29 4.89 3.43 5.74 5.03 10.48z"
            className="oggetti-cls-25"
          ></path>
          <path
            d="M241.34 92.82l-21.67 5.87c1.87 2.53 3.33 5.57 4.21 9.31-.88-3.74-2.34-6.78-4.21-9.31l-18.15 4.92 15.37 31.84 27.26-7.38-2.8-35.25z"
            className="oggetti-cls-7"
          ></path>
        </g>
        <g>
          <path
            d="M300 112.66h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64H300v-10.1z"
            className="oggetti-cls-2"
            transform="rotate(-5.18 320.974 117.748)"
          ></path>
          <path
            d="M292.05 114.92h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-8"
            transform="rotate(174.82 296.08 119.96)"
          ></path>
        </g>
        <g>
          <path
            d="M303.1 102.24h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64H303.1v-10.1z"
            className="oggetti-cls-7"
            transform="rotate(-5.18 324.107 107.257)"
          ></path>
          <path
            d="M295.15 104.49h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-17"
            transform="rotate(174.82 299.176 109.54)"
          ></path>
        </g>
        <g>
          <path
            d="M298.21 92.52h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-40.24v-10.1z"
            className="oggetti-cls-10"
            transform="rotate(-5.18 319.226 97.621)"
          ></path>
          <path
            d="M290.26 94.78h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-15"
            transform="rotate(174.82 294.29 99.824)"
          ></path>
        </g>
        <g>
          <path
            d="M301.31 82.1h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-40.24V82.1z"
            className="oggetti-cls-14"
            transform="rotate(-5.18 322.359 87.13)"
          ></path>
          <path
            d="M293.36 84.35h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-13"
            transform="rotate(174.82 297.386 89.402)"
          ></path>
        </g>
        <g>
          <path
            d="M298.34 72.22h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-40.24v-10.1z"
            className="oggetti-cls-22"
            transform="rotate(-5.18 319.372 77.248)"
          ></path>
          <path
            d="M290.39 74.47h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-16"
            transform="rotate(174.82 294.417 79.518)"
          ></path>
        </g>
        <g>
          <path
            d="M301.44 61.79h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-40.24v-10.1z"
            className="oggetti-cls-7"
            transform="rotate(-5.18 322.5 66.867)"
          ></path>
          <path
            d="M293.49 64.05H301.54V74.14999999999999H293.49z"
            className="oggetti-cls-17"
            transform="rotate(174.82 297.513 69.097)"
          ></path>
        </g>
        <g>
          <path
            d="M339.73 42.14l-.02-.08a1.64 1.64 0 00-2.04-1.11l-38.61 11.34.57 1.94 39.85-11.63c.19-.06.3-.26.25-.45zM301.32 59.98l.59 1.99 38.61-11.33a1.64 1.64 0 001.11-2.04.375.375 0 00-.45-.25l-39.86 11.63z"
            className="oggetti-cls-10"
          ></path>
          <path
            d="M299.63 54.22l-.57-1.94-6.15 1.81a1.64 1.64 0 00-1.11 2.04c.06.2.26.31.45.25l7.38-2.15zM293.69 62.58l.02.08a1.64 1.64 0 002.04 1.11l6.15-1.81-.59-1.99-7.38 2.15c-.19.06-.3.26-.25.45z"
            className="oggetti-cls-15"
          ></path>
        </g>
        <g>
          <path
            d="M295.47 23.18h40.24c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-40.24v-10.1z"
            className="oggetti-cls-24"
            transform="rotate(24.51 316.437 28.24)"
          ></path>
          <path
            d="M289.67 12.82h6.41c.91 0 1.64.74 1.64 1.64v6.82c0 .91-.74 1.64-1.64 1.64h-6.41v-10.1z"
            className="oggetti-cls-27"
            transform="rotate(-155.49 293.697 17.88)"
          ></path>
        </g>
        <g>
          <path
            d="M488.51 31.57c-1.67-3.62-5.16-6.07-9.12-6.5l-64.94-6.91c-3.97-.42-7.89 1.24-10.28 4.43-20.52 27.3-14.61 65.62-14.61 65.62l99.38 10.58h.05s13.84-36.22-.47-67.22z"
            className="oggetti-cls-23"
          ></path>
          <path
            d="M396.81 95.74L389.36 116.34 393.23 116.75 408.49 96.98 396.81 95.74z"
            className="oggetti-cls-11"
          ></path>
          <path
            d="M480.33 104.63L483.27 126.33 479.39 125.92 468.64 103.38 480.33 104.63z"
            className="oggetti-cls-11"
          ></path>
          <path
            d="M433.79 37.33H446.01000000000005V137.28H433.79z"
            className="oggetti-cls-23"
            transform="rotate(-83.93 439.877 87.323)"
          ></path>
          <path
            d="M390.85 75.94s11.74-4.4 50.29-.29c38.55 4.1 49.1 10.87 49.1 10.87l-99.38-10.58zM409.37 35.87c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13zM439.7 39.1c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13zM470.03 42.33c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13z"
            className="oggetti-cls-1"
          ></path>
          <g>
            <path
              d="M407.08 57.41c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13zM437.41 60.64c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13zM467.74 63.87c2.86 1.56 4.86 4.29 5.33 7.41 1.11-2.95 3.64-5.2 6.77-6.13-2.86-1.56-4.86-4.29-5.33-7.41-1.11 2.95-3.64 5.2-6.77 6.13z"
              className="oggetti-cls-1"
            ></path>
          </g>
          <path
            d="M488.94 98.67c-.13-6.16-.65-10.95-4.7-12.79l5.99.64-1.29 12.15zM389.56 88.09c1.43-5.99 2.94-10.56 7.28-11.51l-5.99-.64-1.29 12.15z"
            className="oggetti-cls-1"
          ></path>
          <path
            d="M492.21 68.43l-3.23 30.36-99.43-10.58 3.23-30.36a5.6 5.6 0 00-4.99-6.17l-6.89-.73-.54 5.03s6.07 1.01 4.54 15.39l-2.09 19.68a5.506 5.506 0 004.9 6.06l101.2 10.77c3.03.32 5.74-1.87 6.06-4.9l2.09-19.68c1.53-14.38 7.68-14.09 7.68-14.09l.54-5.03-6.89-.73a5.6 5.6 0 00-6.17 4.99z"
            className="oggetti-cls-11"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Oggetti;