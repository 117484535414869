import React, { useEffect, useState } from "react";
import Notifiche from "../../../assets/sidebar/Notifiche";
import { doc, onSnapshot } from "firebase/firestore";
import { db } from "../../../firebase"; // Assicurati che l'import di 'db' sia corretto
import useUserStore from "../../../stores/userStore";

function Notifica() {
    const user = useUserStore((state) => state.user);
    const [hasNotifiche, setHasNotifiche] = useState(false);
    const [error, setError] = useState(null);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        if (user && user.uid) {
            const userDocRef = doc(db, "users", user.uid);

            const unsubscribe = onSnapshot(userDocRef, (doc) => {
                if (doc.exists()) {
                    setHasNotifiche(doc.data().hasNotifications);
                    setLoading(false);
                } else {
                    setError("User document does not exist");
                    setLoading(false);
                }
            }, (error) => {
                setError("Error fetching user data: " + error.message);
                setLoading(false);
            });

            return () => unsubscribe();
        } else {
            setError("User is not authenticated or ID is not available");
            setLoading(false);
        }
    }, [user]);

  

    return (
        <div className="text-grigio relative">
            {hasNotifiche && (
                <div className="absolute bg-rosso rounded-full w-2 h-2 top-0 right-0"></div>
            )}
            <Notifiche hasNotifiche={hasNotifiche} />
        </div>
    );
}

export default Notifica;
