import React from "react";
import NavLanding from "../../../Components/Molecules/Sidebar/NavLanding";
import LocalHeroesLogo from "../../../assets/Icons/LocalHeroesLogo";
import Bottone from "../../../Components/Atoms/Bottoni/Bottone";
import img from "../../../assets/LocalHeroes/1.jpg";
import SorrisoBianco from "../../../assets/Illustrazioni/Landing/SorrisoBianco";
import { IoRocketOutline } from "react-icons/io5";
import { PiPottedPlant } from "react-icons/pi";
import { GiTreehouse } from "react-icons/gi";
import { FaStar } from "react-icons/fa";
import { TbTargetArrow } from "react-icons/tb";
import SfondoFooter from "../../../assets/Illustrazioni/Landing/SfondoFooter";
import SfondoFooterM from "../../../assets/Illustrazioni/Landing/SfondoFooterM";

function LocalHeroes({ logged }) {
    return (
        <div>
            <NavLanding />
            <div className="">
                <div className="w-full relative">
                    <div className="bg-verde/40 w-full md:h-[60vh] h-[40vh] absolute"></div>
                    <div
                        style={{ backgroundImage: `url(${img})` }}
                        className=" w-full md:h-[60vh] h-[40vh] bg-cover bg-center "
                    ></div>
                    <div className="absolute top-1/2 text-white -translate-y-1/2 md:left-10 left-5 md:pr-0 pr-5">
                        <div className="md:text-7xl text-4xl font-extrabold ">
                            <h3>La forza del Locale</h3>
                            <h3>Un impatto globale</h3>
                        </div>
                        <p className="mt-5 md:text-3xl text-2xl font-light">
                            Il vostro impegno fa la differenza: insieme, stiamo{" "}
                            <br />
                            coinvolgendo sempre più persone a {" "}
                        <span className=" md:text-3xl text-2xl font-bold">
                            donare, condividere e vivere in modo più
                            sostenibile.
                        </span>
                        </p>
                    </div>
                </div>
                <div className="md:flex w-full md:mt-0 mt-5 gap-10 p-10 items-center">
                    <img
                        className="md:w-[35rem] "
                        src={require("../../../assets/PNG/Landing/LocalHeroes.png")}
                        alt=""
                    />
                    <div className="md:mt-0 mt-10">
                        <h3 className="md:text-5xl text-3xl font-bold">
                            Chi sono i <br className="md:hidden" /> Local
                            Heroes?
                        </h3>
                        <p className="text-lg font-light mt-5 text-justify">
                            I Local Heroes sono persone con a cuore la
                            sostenibilità che decidono di contribuire alla
                            missione di Togiftit per diffondere la cultura del
                            riuso e creare un impatto positivo nelle loro
                            comunità. Crediamo che ognuno possa fare la
                            differenza, siamo solo all'inizio di un grande
                            viaggio insieme.
                        </p>
                    </div>
                </div>
                <div className="p-10 relative z-10">
                    <div className="absolute w-[40%] left-1/2 -translate-x-1/2 -translate-y-[37%] -z-10">
                        <SorrisoBianco fill="#E0E2E5" />{" "}
                    </div>
                    <div className="text-5xl font-extrabold text-center">
                        <h3>Unisciti alla</h3>
                        <h3 className="text-verde">nostra missione</h3>
                    </div>
                    <div className="grid grid-cols-1 gap-10 mt-5 md:grid-cols-2">
                        <div className="md:p-10 ">
                            {" "}
                            <img
                                className="rounded-2xl border-2 border-verdeScuro shadow-lg"
                                src={require("../../../assets/PNG/Landing/attivisti.jpg")}
                                alt=""
                            />
                        </div>
                        <div className="md:text-3xl text-2xl md:m-10 border-l-8 border-verdeScuro p-5 flex flex-col justify-between">
                            <p>
                                Ogni giorno, oggetti e cibo ancora utilizzabili
                                vengono sprecati mentre molti ne avrebbero
                                bisogno. <br />
                                <span className="font-bold">
                                    Noi di Togiftit vogliamo cambiare questo.
                                </span>
                            </p>
                            <p>
                                Con il supporto dei nostri Local Heroes, stiamo
                                costruendo una rete di riuso e condivisione che
                                inizia dalla tua comunitàe coinvolge diverse
                                realtà locali.
                            </p>
                        </div>
                    </div>
                </div>
                <div className="flex justify-center">
                    <Bottone>Diventa un local Hero</Bottone>
                </div>

                <div className="flex md:px-0 px-3 flex-col justify-center items-center text-center gap-16 py-16">
                    <div className="flex  flex-col justify-center items-center gap-4">
                        <IoRocketOutline size={60} />
                        <h3 className="text-5xl font-bold text-center">
                            <span className="text-verde">Uniamo le forze:</span>{" "}
                            <br />
                            Diffondiamo il messaggio!
                        </h3>
                        <p className="md:w-[40%] text-xl mt-4">
                            Insieme, iniziamo a promuovere la cultura del riuso.
                            Come un albero che estende i suoi rami, porteremo il
                            messaggio ovunque, mostrando che anche piccoli gesti
                            possono dare vita a un grande cambiamento.
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <PiPottedPlant size={60} />
                        <h3 className="text-5xl font-bold text-center text-verde">
                            Coinvolgiamo i primi <br />
                            sostenitori
                        </h3>
                        <p className="md:w-[40%] text-xl mt-4">
                            Portiamo dentro amici, familiari e persone vicine.
                            Seminiamo idee e creiamo un'onda di condivisione che
                            si dionde nella nostra comunità, rendendola più
                            aperta e consapevole.
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <GiTreehouse size={60} />
                        <h3 className="text-5xl font-bold text-center text-verde">
                            Costruiamo una rete di <br />
                            attori locali
                        </h3>
                        <p className="md:w-[40%] text-xl mt-4">
                            Siamo parte di qualcosa di più grande: coinvolgeremo
                            enti, scuole, associazioni, comuni, artigiani e
                            restauratori locali. Insieme, organizzeremo
                            iniziative che accendono i riettori sul riuso e
                            sulla sostenibilità, creando collaborazioni che
                            fanno davvero la dierenza.
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <FaStar size={60} />
                        <h3 className="text-5xl font-bold text-center text-verde">
                            Cresciamo insieme
                        </h3>
                        <p className="md:w-[40%] text-xl mt-4">
                            Con ogni passo, vedremo la nostra rete raorzarsi e
                            il movimento del riuso prendere sempre più spazio.
                            Siamo tutti protagonisti di questo cambiamento, e il
                            nostro impatto continuerà a crescere.
                        </p>
                    </div>
                    <div className="flex flex-col justify-center items-center gap-4">
                        <TbTargetArrow size={60} />
                        <h3 className="text-5xl font-bold text-center">
                            <span className="text-verde">Traguardo:</span>{" "}
                            <br />
                            Entrare nel cuore delle persone
                        </h3>
                        <p className="md:w-[40%] text-xl mt-4">
                            Il nostro obiettivo è entrare nel cuore della
                            comunità con la nostra missione. Una volta piantato
                            il seme del riuso, insieme metteremo in atto un
                            cambiamento reale, rendendo la nostra zona più
                            sostenibile e consapevole.
                        </p>
                    </div>
                    <h3 className="text-5xl font-bold text-center">
                        Il tuo contributo{" "}
                        <span className="text-verde">è importante</span>
                    </h3>
                </div>
            </div>
            <div className="w-full md:h-[70vh] h-[30vh] mb-[4rem] flex justify-center gap-5 mt-[15%] relative">
                <div className="w-full absolute z-0 bottom-0 hidden md:block">
                    <SfondoFooter fill={"#E0E2E5"} />
                </div>
                <div className="w-full absolute z-0 bottom-0 md:hidden ">
                    <SfondoFooterM fill={"#E0E2E5"} />
                </div>
                <div className="w-full h-[50%] z-10 flex flex-col md:flex-row justify-evenly items-center md:mt-[0%] mt-[30%] ">
                    <button
                        onClick={() =>
                            (window.location.href = logged ? "/" : "/webapp")
                        }
                        className="md:px-14 p-3 md:py-10 rounded-full text-white text-xl font-semibold bg-verdeScuro z-10"
                    >
                        Visita la <span className="font-bold">WebApp</span>
                    </button>
                    <button
                        onClick={() =>
                            (window.location.href = logged ? "/" : "/webapp")
                        }
                        className="md:px-14 p-3 md:py-10  rounded-full text-white text-xl font-semibold bg-verdeScuro z-10"
                    >
                        Diventa un <span className="font-bold">Local Hero</span>
                    </button>
                </div>
            </div>
        </div>
    );
}

export default LocalHeroes;
